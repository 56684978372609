/**
 * 레드라인(Tracking change, redlining) 테스트 상황별 경우들
 * 아래 사항들에 대한 테스트는 전부 각자의 상황을 교차해서 테스트해야 하므로 경우의 수를 전부 곱한만큼의 테스트가 필요
 * - 커서 위치별 테스트
 * 1. 문서의 맨 앞
 * 2. 문서의 맨 뒤
 * 3. 문장의 맨앞
 * 4. 문장의 중간
 * 5. 문장의 끝
 * 6. 문단의 맨앞
 * 7. 문단의 중간
 * 8. 문단의 끝
 * 9. 리스트의 맨앞
 * 10. 리스트의 중간
 * 11. 리스트의 끝
 * 12. 테이블의 맨앞
 * 13. 테이블의 중간
 * 14. 테이블의 끝
 * 15. 문서의 맨 앞에서부터 맨끝까지 블록을 선택
 *
 * - 문장의 상황
 * 1. 평범한 텍스트로만 이루어진 문장
 * 2. 변경사항 추적 문장 부분의 맨앞
 * 3. 변경사항 추적 문장 부분의 중간
 * 4. 변경사항 추적 문장 부분의 끝
 * 5. 변경사항 추적 부분이 문서의 맨 앞에 있고 이후에 평범한 문장이 있을때
 * 6. 변경사항 추적 부분이 문서의 맨 뒤에 있고 이전에 평범한 문장이 있을때
 * 7. 변경사항 추적 부분이 문서의 가운데 있고 앞과 뒤에 평범한 문장이 있을때
 * 8. 평범한 문장이 리스트에 포함되어 있을때
 * 9. 평범한 문장이 테이블에 포함되어 있을때
 * 10. 변경사항 추적 문장이 리스트안에 처음부터 끝까지 포함되어 있을때
 * 11. 변경사항 추적 문장이 리스트안에 처음에 포함되어 있고 뒤에 평범한 문장이 있을때
 * 12. 변경사항 추적 문장이 리스트안에 중간에 포함되어 있고 앞과 뒤에 평범한 문장이 있을때
 * 13. 변경사항 추적 문장이 리스트안에 끝에 포함되어 있고 앞에 평범한 문장이 있을때
 * 14. 빈 문장
 *
 * - 행위
 * 1. 숫자 타이핑
 * 2. Backspace
 * 3. IME 입력(한글/일본어/중국어/특수문자)
 * 4. 잘라내기
 * 5. 복사하기
 * 6. 붙여넣기
 * 7. 내부 문장을 문서 내부로 드래그 하여 이동
 * 8. 브라우저 외부에서 문장을 문서 내부로 드래그 하여 이동
 * 9. IME 입력중 첫글자 자음만 입력한 상황에 Backspace
 * 10. IME 입력중 첫글자 자음(ㄱ)만 입력한 상황에 Backspace 후 다시 자음을 입력한 상황
 * 11. IME 입력중 첫글자 자음(ㄱ) 입력 후 숫자(1) 입력
 * 12. IME 입력중 첫글자 자음(ㄱ) 입력 후 숫자(1) 입력 후 Backspace 여러번
 * 13. IME 입력중 여러글자(가나다라) 입력중 중간에 Backspace 여러번
 * 14. IME 입력중 여러글자(가나다라) 입력중 중간에 Backspace 후 다시 글자 입력
 * 15. IME 입력중 여러글자(가나다라) 입력중 Delete
 * 16. IME 입력중 여러글자(가나다라) 입력중 화살표 이동
 * 17. IME 입력중 첫글자 자음(ㄱ) 입력 후 화살표 이동
 * 18. IME 입력중 여러글자(가나다라) 입력중 화살표 이동 후 Backspace
 * 19. Tab 키 입력
 * 20. Shift + Tab 키 입력
 * 21. Enter 키 입력
 * 22. Shift + Enter 키 입력
 * 23. Delete 키 입력
 * 24. 들여쓰기 된 문장에서 Tab
 * 25. 들여쓰기 된 문장에서 Shift + Tab
 *
 * - 모드
 * 1. 편집모드
 * 2. 리뷰모드
 */

import type { RevisionAuthorInfo } from './types';
import { Editor, Extension, getMarkRange, getMarksBetween, isMarkActive, type Range } from '@tiptap/core';
import { Slice, Fragment, MarkType, Node as _Node, Mark } from '@tiptap/pm/model';
import { EditorState, Plugin, PluginKey, TextSelection, type Transaction } from '@tiptap/pm/state';
import { AddMarkStep, RemoveMarkStep, ReplaceAroundStep, replaceStep, ReplaceStep, Step } from '@tiptap/pm/transform';
import InsertRevision, { MARK_INSERT_REVISION } from './InsertRevision';
import DeleteRevision, { MARK_DELETE_REVISION } from './DeleteRevision';
import FormatRevision, { MARK_FORMAT_REVISION } from './FormatRevision';
import MoveFromRevision, { MARK_MOVE_FROM_REVISION } from './MoveFromRevision';
import MoveToRevision, { MARK_MOVE_TO_REVISION } from './MoveToRevision';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/sveltekit';
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view';
import { cloneDeep, isEqual, remove } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

export interface WordTrackChangeOptions {
    HTMLAttributes: Record<string, any>;
}

export interface WordTrackChangeStorage {
    enabled: boolean;
    authorInfo: RevisionAuthorInfo;
    highlightId: string | null;
    hoveredRevisionId: string | null;
    showDeleteRevision: boolean;
    colorMode: 'user' | 'ins/del';
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        trackchange: {
            setTrackChangeStatus: (enabled: boolean) => ReturnType;
            toggleTrackChangeStatus: () => ReturnType;
            setTrackChangeDecorationColorMode: (colorMode: 'user' | 'ins/del') => ReturnType; // 레드라인을 영역을 나타내는 색상을 사용자별로 표시할지, Insert/Delete로 표시할지 결정합니다.
            acceptRevision: (revisionId: string) => ReturnType; // revisionId 를 가지는 모든 영역을 승인
            rejectRevision: (revisionId: string) => ReturnType; // revisionId 를 가지는 모든 영역을 거절
            accpetFocusedRevision: () => ReturnType; // 커서가 위치한 영역의 Revision 승인
            rejectFocusedRevision: () => ReturnType; // 커서가 위치한 영역의 Revision 거절
            accpetSelectedRevision: () => ReturnType; // 선택영역이 있을때는 선택한 부분만 승인 / 선택영역이 없을때는 커서가 위치한 Revision 승인
            rejectSelectedRevision: () => ReturnType; // 선택영역이 있을때는 선택한 부분만 거절 / 선택영역이 없을때는 커서가 위치한 Revision 거절
            acceptAllRevision: () => ReturnType;
            rejectAllRevision: () => ReturnType;
            /**
             * 편집을 시도할 사용자를 에디터에 세팅합니다.
             * @param authorInfo 변경을 수행한 사용자의 정보
             */
            updateAuthorInfo: (authorInfo: RevisionAuthorInfo) => ReturnType;
            setRevisionFocusHighlight: (revisionId: string) => ReturnType;
            unsetRevisionFocusHighlight: () => ReturnType;
            toggleShowDeleteRevision: (isShow?: boolean) => ReturnType; // DeleteRevision을 보여줄지 여부를 토글합니다.
        };
    }
}

const LOG_ENABLED = true;

const EXTENSION_NAME = 'wordTrackChange';
const META_CHANGED_TRACK_MAUALLY = 'trackManualChanged';
const PLUGIN_KEY_TRACKCHANGE_HIGHLIGHT = 'trackchange-highlight';
const META_TRACK_CHANGE_HIGHLIGHT = 'trackchange-highlight';

const IME_STATUS_NORMAL = 0;
const IME_STATUS_START = 1;
const IME_STATUS_CONTINUE = 2;
const IME_STATUS_FINISHED = 3;
type IME_STATUS_TYPE = 0 | 1 | 2 | 3;
let isStartInputIME = false;
let composingStatus: IME_STATUS_TYPE = 0;
let currCompositionStartChar = '';
let currCompositionChar = '';
let currCompositionUpdateChar = '';
let lastPressedKeyChar = '';
let lastPressedKey = '';
let isEmptyStartInput = false;
let prevOldFromPos = 0;
let prevCompositionChar = '';
let prevComposition = 0;
let isPrevSelectionModifiedIME = false;
let isMouseMoved = false; // 마우스가 움직이지 않았는데 타이핑중에 마우스 오버가 발생하면 동작이 겹치면서 생기는 마우스 오버 이벤트를 무시하기 위함
let currOldState: EditorState;
let currNewState: EditorState;
let trPrevOldStateWithCompositionStart: EditorState;
let trPrevNewStateWithCompositionStart: EditorState;
let trPrevOldStateWithSelectionCompositionStart: EditorState;
let trPrevNewStateWithSelectionCompositionStart: EditorState;

function _acceptRevision(tr: Transaction, revisionId: string) {
    // tr에서 revisionId 를 가진 InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision 을 찾아서 해당 revisionId를 가진 mark를 찾아서 승인합니다.
    let offset = 0;
    const emptyBlockRange: { start: number; end: number }[] = [];
    tr.doc.descendants((node, pos) => {
        if (!node.isInline) return;
        const findedMark = node.marks.find(m => m.attrs.revision?.id === revisionId);
        if (findedMark) {
            if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION, MARK_FORMAT_REVISION].includes(findedMark.type.name)) {
                tr.removeMark(pos - offset, pos - offset + node.nodeSize, findedMark.type);
            } else {
                tr.deleteRange(pos - offset, pos - offset + node.nodeSize);
                offset += node.nodeSize;
            }

            // 삭제 후 상위 node가 Block이고 child node가 없는 경우에는 빈 Block을 삭제합니다.
            const currPos = pos - offset + node.nodeSize;
            tr.doc.nodesBetween(currPos, currPos, (node, pos) => {
                if (node.type.isBlock && node.childCount === 0) {
                    emptyBlockRange.push({ start: pos, end: pos + node.nodeSize });
                }
            });
        }
    });
    emptyBlockRange.reverse().forEach(range => {
        tr.deleteRange(range.start, range.end);
    });
    return tr;
}

function _rejectRevision(tr: Transaction, revisionId: string) {
    // tr에서 revisionId 를 가진 InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision 을 찾아서 해당 revisionId를 가진 mark를 찾아서 거절합니다.
    let offset = 0;
    const emptyBlockRange: { start: number; end: number }[] = [];
    tr.doc.descendants((node, pos) => {
        if (!node.isInline) return;
        const findedMark = node.marks.find(m => m.attrs.revision?.id === revisionId);
        if (findedMark) {
            if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(findedMark.type.name)) {
                tr.deleteRange(pos - offset, pos - offset + node.nodeSize);
                offset += node.nodeSize;
            } else if ([MARK_FORMAT_REVISION].includes(findedMark.type.name)) {
                // TODO: 원래 format 으로 맞춰주어야함
                tr.deleteRange(pos - offset, pos - offset + node.nodeSize);
                offset += node.nodeSize;
            } else {
                tr.removeMark(pos - offset, pos - offset + node.nodeSize, findedMark.type);
            }

            // 삭제 후 상위 node가 Block이고 child node가 없는 경우에는 빈 Block을 삭제합니다.
            const currPos = pos - offset + node.nodeSize;
            tr.doc.nodesBetween(currPos, currPos, (node, pos) => {
                if (node.type.isBlock && node.childCount === 0) {
                    emptyBlockRange.push({ start: pos, end: pos + node.nodeSize });
                }
            });
        }
    });
    emptyBlockRange.reverse().forEach(range => {
        tr.deleteRange(range.start, range.end);
    });
}

function _accpetSelectedRevision(tr: Transaction, state: EditorState) {
    // 선택된 범위 안에서만 InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision 을 찾아서 해당 mark를 찾아서 승인합니다.
    const { doc, schema, selection } = state;
    const from = selection.from;
    const to = selection.to;
    if (from === to) return;
    const willUpdateRevisionInfos: { node: _Node; pos: number }[] = [];

    doc.nodesBetween(from, to + 1, (node, pos) => {
        const revisionMarks = node.marks.filter(m => m.attrs.revision);
        if (revisionMarks.length) {
            willUpdateRevisionInfos.push({ node, pos });
            return false;
        }
    });
    for (let i = willUpdateRevisionInfos.length - 1; i >= 0; i--) {
        const { node, pos } = willUpdateRevisionInfos[i];
        const revisionMarks = node.marks.filter(m => m.attrs.revision);
        if (revisionMarks.length) {
            // revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾아서 승인합니다.
            const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
            if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION, MARK_FORMAT_REVISION].includes(latestRevisionMark.type.name)) {
                revisionMarks.forEach(m => {
                    tr.removeMark(Math.max(from, pos), Math.min(to, pos + node.nodeSize), m.type);
                });
            } else {
                tr.deleteRange(Math.max(from, pos), Math.min(to, pos + node.nodeSize));
            }

            // 삭제 후 상위 node가 Block이고 child node가 없는 경우에는 빈 Block을 삭제합니다.
            const parentNode = tr.doc.resolve(pos).parent;
            if (parentNode.type.isBlock && parentNode.childCount === 0) {
                tr.deleteRange(pos - 1, pos + 1);
            }
        }
    }
}

function _rejectSelectedRevision(tr: Transaction, state: EditorState) {
    const { doc, schema, selection } = state;
    const from = selection.from;
    const to = selection.to;
    if (from === to) return;
    const willUpdateRevisionInfos: { node: _Node; pos: number }[] = [];

    doc.nodesBetween(from, to + 1, (node, pos) => {
        const revisionMarks = node.marks.filter(m => m.attrs.revision);
        if (revisionMarks.length) {
            willUpdateRevisionInfos.push({ node, pos });
            return false;
        }
    });
    for (let i = willUpdateRevisionInfos.length - 1; i >= 0; i--) {
        const { node, pos } = willUpdateRevisionInfos[i];
        const revisionMarks = node.marks.filter(m => m.attrs.revision);
        if (revisionMarks.length) {
            // revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾아서 거절합니다.
            const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
            if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name)) {
                tr.deleteRange(Math.max(from, pos), Math.min(to, pos + node.nodeSize));
            } else if ([MARK_FORMAT_REVISION].includes(latestRevisionMark.type.name)) {
                // TODO: 원래 format 으로 맞춰주어야함{
                tr.deleteRange(Math.max(from, pos), Math.min(to, pos + node.nodeSize));
            } else {
                revisionMarks.forEach(m => {
                    tr.removeMark(Math.max(from, pos), Math.min(to, pos + node.nodeSize), m.type);
                });
            }

            // 삭제 후 상위 node가 Block이고 child node가 없는 경우에는 빈 Block을 삭제합니다.
            const parentNode = tr.doc.resolve(pos).parent;
            if (parentNode.type.isBlock && parentNode.childCount === 0) {
                tr.deleteRange(pos - 1, pos + 1);
            }
        }
    }
}

export function createWordTrackChangeExtensionInstance() {
    const wordTrackChangeExtensionInstance = Extension.create<WordTrackChangeOptions, WordTrackChangeStorage>({
        name: EXTENSION_NAME,
        addStorage() {
            return {
                enabled: false,
                authorInfo: {
                    id: null,
                    displayName: 'Unnamed',
                },
                highlightId: null,
                hoveredRevisionId: null,
                showDeleteRevision: true,
                colorMode: 'user',
            };
        },
        addExtensions() {
            return [InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision];
        },
        addCommands() {
            return {
                setTrackChangeStatus:
                    (enabled: boolean) =>
                    ({ tr, state, dispatch }) => {
                        this.storage.enabled = enabled;
                        return true;
                    },
                toggleTrackChangeStatus:
                    () =>
                    ({ tr, state, dispatch }) => {
                        this.storage.enabled = !this.storage.enabled;
                        return true;
                    },
                setTrackChangeDecorationColorMode:
                    (colorMode = 'user') =>
                    ({ tr, state, dispatch }) => {
                        this.storage.colorMode = colorMode;
                        tr.setMeta(META_TRACK_CHANGE_HIGHLIGHT, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                acceptRevision:
                    revisionId =>
                    ({ tr, state, dispatch }) => {
                        _acceptRevision(tr, revisionId);
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                rejectRevision:
                    revisionId =>
                    ({ tr, state, dispatch }) => {
                        _rejectRevision(tr, revisionId);
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // 현재 커서가 위한 node의 revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾아서 selection을 재지정하고 accpetSelectedRevision을 실행합니다.
                accpetFocusedRevision:
                    () =>
                    ({ tr, state, dispatch, editor }) => {
                        const { doc, schema, selection } = state;
                        const from = selection.from;
                        const to = selection.to;
                        if (from !== to) {
                            _accpetSelectedRevision(tr, state);
                            tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                            if (dispatch) dispatch(tr);
                            return true;
                        }
                        let findedMark;
                        doc.nodesBetween(from, to + 1, (node, pos) => {
                            const revisionMarks = node.marks.filter(m => m.attrs.revision);
                            if (revisionMarks.length) {
                                // revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾습니다.
                                const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
                                findedMark = latestRevisionMark;
                            }
                        });
                        if (findedMark) {
                            _acceptRevision(tr, findedMark.attrs.revision.id);
                            tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                            if (dispatch) dispatch(tr);
                            return true;
                        }
                        return false;
                    },
                rejectFocusedRevision:
                    () =>
                    ({ tr, state, dispatch, editor }) => {
                        const { doc, schema, selection } = state;
                        const from = selection.from;
                        const to = selection.to;
                        if (from !== to) {
                            _rejectSelectedRevision(tr, state);
                            tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                            if (dispatch) dispatch(tr);
                            return true;
                        }
                        let findedMark;
                        doc.nodesBetween(from, to + 1, (node, pos) => {
                            const revisionMarks = node.marks.filter(m => m.attrs.revision);
                            if (revisionMarks.length) {
                                // revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾습니다.
                                const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
                                findedMark = latestRevisionMark;
                            }
                        });
                        if (findedMark) {
                            _rejectRevision(tr, findedMark.attrs.revision.id);
                            tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                            if (dispatch) dispatch(tr);
                            return true;
                        }
                        return false;
                    },
                accpetSelectedRevision:
                    () =>
                    ({ tr, state, dispatch }) => {
                        _accpetSelectedRevision(tr, state);
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                rejectSelectedRevision:
                    () =>
                    ({ tr, state, dispatch }) => {
                        _rejectSelectedRevision(tr, state);
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                acceptAllRevision:
                    () =>
                    ({ tr, state, dispatch }) => {
                        // tr에서 InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision 을 찾아서 해당 mark를 찾아서 승인합니다.
                        const { doc, schema } = state;
                        const willUpdateRevisionIds: string[] = [];
                        doc.descendants((node, pos) => {
                            const revisionMarks = node.marks.filter(m => m.attrs.revision);
                            if (revisionMarks.length) {
                                // revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾아서 거절합니다.
                                const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
                                willUpdateRevisionIds.push(latestRevisionMark.attrs.revision.id);
                                return false;
                            }
                        });
                        for (let i = willUpdateRevisionIds.length - 1; i >= 0; i--) {
                            _acceptRevision(tr, willUpdateRevisionIds[i]);
                        }
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                rejectAllRevision:
                    () =>
                    ({ tr, state, dispatch }) => {
                        // tr에서 InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision 을 찾아서 해당 mark를 찾아서 거절합니다.
                        const { doc, schema } = state;
                        const willUpdateRevisionIds: string[] = [];
                        doc.descendants((node, pos) => {
                            const revisionMarks = node.marks.filter(m => m.attrs.revision);
                            if (revisionMarks.length) {
                                // revision.createdAt 이 가장 최신인 mark를 찾아서 해당 mark를 찾아서 거절합니다.
                                const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
                                willUpdateRevisionIds.push(latestRevisionMark.attrs.revision.id);
                                return false;
                            }
                        });
                        for (let i = willUpdateRevisionIds.length - 1; i >= 0; i--) {
                            _rejectRevision(tr, willUpdateRevisionIds[i]);
                        }
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                updateAuthorInfo:
                    (authorInfo: RevisionAuthorInfo) =>
                    ({ tr, state, dispatch }) => {
                        this.storage.authorInfo = authorInfo;
                        tr.setMeta(META_CHANGED_TRACK_MAUALLY, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                setRevisionFocusHighlight:
                    (revisionId: string) =>
                    ({ tr, state, dispatch }) => {
                        this.storage.highlightId = revisionId;
                        tr.setMeta(META_TRACK_CHANGE_HIGHLIGHT, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                unsetRevisionFocusHighlight:
                    () =>
                    ({ tr, state, dispatch }) => {
                        this.storage.highlightId = null;
                        tr.setMeta(META_TRACK_CHANGE_HIGHLIGHT, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                toggleShowDeleteRevision:
                    isShow =>
                    ({ tr, state, dispatch }) => {
                        this.storage.showDeleteRevision = isShow ?? !this.storage.showDeleteRevision;
                        tr.setMeta(META_TRACK_CHANGE_HIGHLIGHT, true);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
            };
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        addProseMirrorPlugins() {
            const createNametag = ({ id, name, customClass }) => {
                const cursor = document.createElement('span');
                cursor.className = 'word-track-change-nametag relative word-break-normal pointer-events-none';
                cursor.dataset.revisionId = id;
                const nameTag = document.createElement('div');
                nameTag.className = 'inline-block absolute bottom-[-1rem] left-0 text-xs user-select-none text-white whitespace-nowrap indent-0 radius-sm px-0.5 font-semibold z-20';
                nameTag.style.fontFamily = 'Pretendard';
                if (name) {
                    nameTag.insertBefore(document.createTextNode(name), null);
                } else {
                    nameTag.classList.add('h-1');
                    nameTag.classList.add('w-1');
                }
                nameTag.classList.add(customClass);
                const nonbreakingSpace1 = document.createTextNode('\u2060');
                const nonbreakingSpace2 = document.createTextNode('\u2060');
                cursor.insertBefore(nonbreakingSpace1, null);
                cursor.insertBefore(nameTag, null);
                cursor.insertBefore(nonbreakingSpace2, null);
                return cursor;
            };
            const createRevisionDecoration = (doc: _Node) => {
                const decorations: Decoration[] = [];
                const userDatas: { [userId: string]: { node: _Node; pos: number; latestRevisionMark: Mark }[] } = {};
                let selectedNametagDecoration: Decoration | null = null;
                const myUniqueId = `${this.storage.authorInfo.id}-${this.storage.authorInfo.displayName}`;

                if (this.storage.colorMode === 'user') {
                    doc.descendants((node, pos) => {
                        const revisionMarks = node.marks.filter(m => m.attrs.revision);
                        if (node.isInline && node.marks.length && revisionMarks.length) {
                            const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
                            const uniqueId = `${latestRevisionMark.attrs.revision.authorInfo.id}-${latestRevisionMark.attrs.revision.authorInfo.displayName}`;
                            if (userDatas[uniqueId]) {
                                userDatas[uniqueId].push({ node, pos, latestRevisionMark });
                            } else {
                                userDatas[uniqueId] = [{ node, pos, latestRevisionMark }];
                            }
                            return false;
                        }
                    });

                    // userRevisions 를 authorInfo.id 값으로 정렬된 배열로 바꿉니다. 이후에 현재 사용자의 revision이 가장 위에 오도록 정렬합니다.
                    let orderedDatas = Object.entries(userDatas).sort(([pId, pData], [nId, nData]) => {
                        if (pId === myUniqueId) return -1;
                        if (nId === myUniqueId) return 1;
                        if (pId > nId) return 1;
                        if (pId < nId) return -1;
                        return 0;
                    });
                    if (orderedDatas[0]?.[0] !== myUniqueId) {
                        orderedDatas = [[myUniqueId, []], ...orderedDatas];
                    }

                    orderedDatas.forEach(([uniqueId, userRevisions], idx) => {
                        let customColorClass = '';
                        if (idx === 0) {
                            customColorClass = 'text-red-600 selection:bg-red-200';
                        } else if (idx === 1) {
                            customColorClass = 'text-teal-600 selection:bg-teal-200';
                        } else if (idx === 2) {
                            customColorClass = 'text-orange-600 selection:bg-orange-200';
                        } else if (idx === 3) {
                            customColorClass = 'text-indigo-600 selection:bg-indigo-200';
                        } else if (idx === 4) {
                            customColorClass = 'text-green-600 selection:bg-green-200';
                        } else {
                            customColorClass = 'text-yellow-600 selection:bg-yellow-200';
                        }

                        userRevisions.forEach(({ node, pos, latestRevisionMark }) => {
                            let customTextDecoClass = '';
                            if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name)) {
                                customTextDecoClass = 'underline';
                            } else if ([MARK_DELETE_REVISION, MARK_MOVE_FROM_REVISION].includes(latestRevisionMark.type.name)) {
                                customTextDecoClass = 'line-through';
                            }
                            let etcClass = '';
                            if ([MARK_FORMAT_REVISION].includes(latestRevisionMark.type.name)) {
                                etcClass = 'bg-blue-200';
                            }

                            let hoveredClass = '';
                            if (latestRevisionMark.attrs.revision.id === this.storage.hoveredRevisionId) {
                                if (idx === 0) {
                                    hoveredClass = 'bg-red-100';
                                } else if (idx === 1) {
                                    hoveredClass = 'bg-teal-100';
                                } else if (idx === 2) {
                                    hoveredClass = 'bg-orange-100';
                                } else if (idx === 3) {
                                    hoveredClass = 'bg-indigo-100';
                                } else if (idx === 4) {
                                    hoveredClass = 'bg-green-100';
                                } else {
                                    hoveredClass = 'bg-yellow-100';
                                }
                            }

                            decorations.push(
                                Decoration.inline(pos, pos + node.nodeSize, {
                                    class: twMerge(customTextDecoClass, customColorClass, etcClass, hoveredClass),
                                }),
                            );

                            if (this.storage.hoveredRevisionId === latestRevisionMark.attrs.revision.id) {
                                let customNametagBgClass = '';
                                if (idx === 0) {
                                    customNametagBgClass = 'bg-red-600';
                                } else if (idx === 1) {
                                    customNametagBgClass = 'bg-teal-600';
                                } else if (idx === 2) {
                                    customNametagBgClass = 'bg-orange-600';
                                } else if (idx === 3) {
                                    customNametagBgClass = 'bg-indigo-600';
                                } else if (idx === 4) {
                                    customNametagBgClass = 'bg-green-600';
                                } else {
                                    customNametagBgClass = 'bg-yellow-600';
                                }

                                selectedNametagDecoration = Decoration.widget(
                                    pos + node.nodeSize,
                                    (view, getPos) => {
                                        return createNametag({
                                            id: latestRevisionMark.attrs.revision.id,
                                            name: latestRevisionMark.attrs.revision.authorInfo.displayName,
                                            customClass: `${customNametagBgClass}`,
                                        });
                                    },
                                    { side: -1 },
                                );
                            }
                        });
                    });
                } else if (this.storage.colorMode === 'ins/del') {
                    // ins/del 모드에서는 insertRevision, moveToRevision 은 색상을 녹색으로, deleteRevision, moveFromRevision 은 색상을 빨간색으로 표시합니다.
                    doc.descendants((node, pos) => {
                        const revisionMarks = node.marks.filter(m => m.attrs.revision);
                        if (node.isInline && node.marks.length && revisionMarks.length) {
                            const latestRevisionMark = revisionMarks.reduce((prev, current) => (dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current));
                            const customColorClass = [MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name)
                                ? 'text-green-600 selection:bg-green-200'
                                : 'text-red-600 selection:bg-red-200';
                            const customTextDecoClass = [MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name) ? 'underline' : 'line-through';
                            const etcClass = [MARK_FORMAT_REVISION].includes(latestRevisionMark.type.name) ? '' : ''; // format revision에 대한 처리가 필요하면 추가

                            // hover 시에 대한 처리
                            let hoveredClass = '';
                            if (latestRevisionMark.attrs.revision.id === this.storage.hoveredRevisionId) {
                                if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name)) {
                                    hoveredClass = 'bg-green-100';
                                } else if ([MARK_DELETE_REVISION, MARK_MOVE_FROM_REVISION].includes(latestRevisionMark.type.name)) {
                                    hoveredClass = 'bg-red-100';
                                }
                            }

                            decorations.push(
                                Decoration.inline(pos, pos + node.nodeSize, {
                                    class: twMerge(customTextDecoClass, customColorClass, etcClass, hoveredClass),
                                }),
                            );

                            // 네임택에 대한 처리
                            if (latestRevisionMark.attrs.revision.id === this.storage.hoveredRevisionId) {
                                let customNametagBgClass = '';
                                if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name)) {
                                    customNametagBgClass = 'bg-green-600';
                                } else if ([MARK_DELETE_REVISION, MARK_MOVE_FROM_REVISION].includes(latestRevisionMark.type.name)) {
                                    customNametagBgClass = 'bg-red-600';
                                }

                                selectedNametagDecoration = Decoration.widget(
                                    pos + node.nodeSize,
                                    (view, getPos) => {
                                        return createNametag({
                                            id: latestRevisionMark.attrs.revision.id,
                                            name: latestRevisionMark.attrs.revision.authorInfo.displayName,
                                            customClass: `${customNametagBgClass}`,
                                        });
                                    },
                                    { side: -1 },
                                );
                            }
                        }
                    });
                }

                if (selectedNametagDecoration) {
                    decorations.push(selectedNametagDecoration);
                }
                return decorations;
            };
            return [
                // 현재 선택된 storage.highlightId를 가진 mark를 찾아서 하이라이트 처리합니다.
                new Plugin({
                    key: new PluginKey<any>(PLUGIN_KEY_TRACKCHANGE_HIGHLIGHT),
                    props: {
                        decorations(state) {
                            return this.getState(state);
                        },
                        handleDOMEvents: {
                            mousemove: (view, event) => {
                                isMouseMoved = true;
                            },
                            mouseover: (view, event) => {
                                if (!isMouseMoved) return;
                                isMouseMoved = false;
                                const { target } = event;
                                const hoveredNode = target?.closest('[data-revision]');
                                if (hoveredNode) {
                                    const hoveredRevisionId = JSON.parse(hoveredNode.dataset.revision).id;
                                    if (this.storage.hoveredRevisionId !== hoveredRevisionId) {
                                        this.storage.hoveredRevisionId = hoveredRevisionId;
                                        view.dispatch(view.state.tr.setMeta(META_TRACK_CHANGE_HIGHLIGHT, { decoChange: true }));
                                    }
                                }
                            },
                            mouseout: (view, event) => {
                                if (!isMouseMoved) return;
                                if (this.storage.hoveredRevisionId) {
                                    this.storage.hoveredRevisionId = null;
                                    view.dispatch(view.state.tr.setMeta(META_TRACK_CHANGE_HIGHLIGHT, { decoChange: true }));
                                }
                            },
                        },
                    },
                    state: {
                        init: (_, { doc }) => {
                            const decorations: Decoration[] = createRevisionDecoration(doc);
                            doc.descendants((node, pos) => {
                                const revisionMarks = node.marks.filter(m => m.attrs.revision);
                                if (revisionMarks.length) {
                                    const latestRevisionMark = revisionMarks.reduce((prev, current) =>
                                        dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current,
                                    );
                                    if (latestRevisionMark.attrs.revision.id === this.storage.highlightId) {
                                        decorations.push(
                                            Decoration.inline(pos, pos + node.nodeSize, {
                                                class: 'trackchange-highlight ring ring-blue-300',
                                            }),
                                        );
                                    }
                                    // decoration으로 DeleteRevision을 전부 보여주거나 숨깁니다.
                                    if (latestRevisionMark.type.name === MARK_DELETE_REVISION) {
                                        if (!this.storage.showDeleteRevision) {
                                            decorations.push(Decoration.inline(pos, pos + node.nodeSize, { class: 'hidden' }));
                                        }
                                    }
                                }
                            });

                            return DecorationSet.create(doc, decorations);
                        },
                        apply: (tr, value, oldState, newState) => {
                            const meta = tr.getMeta(META_TRACK_CHANGE_HIGHLIGHT) || tr.getMeta(META_CHANGED_TRACK_MAUALLY);
                            if (!tr.docChanged && !meta) {
                                return value;
                            }

                            const { doc } = tr;
                            const decorations: Decoration[] = createRevisionDecoration(doc);
                            doc.descendants((node, pos) => {
                                const revisionMarks = node.marks.filter(m => m.attrs.revision);
                                if (revisionMarks.length) {
                                    const latestRevisionMark = revisionMarks.reduce((prev, current) =>
                                        dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current,
                                    );
                                    if (latestRevisionMark.attrs.revision.id === this.storage.highlightId) {
                                        decorations.push(
                                            Decoration.inline(pos, pos + node.nodeSize, {
                                                class: 'trackchange-highlight ring ring-blue-300',
                                            }),
                                        );
                                    }
                                    // decoration으로 DeleteRevision을 전부 보여주거나 숨깁니다.
                                    if (latestRevisionMark.type.name === MARK_DELETE_REVISION) {
                                        if (!this.storage.showDeleteRevision) {
                                            decorations.push(Decoration.inline(pos, pos + node.nodeSize, { class: 'hidden' }));
                                        }
                                    }
                                }
                            });

                            return DecorationSet.create(tr.doc, decorations);
                        },
                    },
                }),
                // IME 에 대한 동작 이벤트를 수신받기 위한 부분
                new Plugin({
                    key: new PluginKey<any>('composing-check'),
                    props: {
                        handleDOMEvents: {
                            keydown: (view, event) => {
                                /**
                                 * MacOS 에서는 IME 입력이 진행될때 keydown 값에 자음이나 모음의 값이 나온다.
                                 * Windows 에서는 IME 입력이 진행될때 keydown 값에 Process 라는 값이 나온다.
                                 * MacOS 에서는 IME 입력중 일반입력이 들어오면 keydown 이벤트가 먼저 실행되고 compositionend 이벤트가 실행되게 되면서 end에 값이 '가1' 과 같이 함께 나오면서 한번의 트랜잭션이 실행된다.
                                 * Windows 에서는 IME 입력중 일반입력이 들어오면 compositionend 이벤트가 먼저 실행되고 keydown 이벤트가 실행되면서 두번의 트랜젝션이 실행된다.
                                 */
                                LOG_ENABLED && console.log('keydown', event.key);
                                lastPressedKey = event.key;
                            },
                            keypress: (view, event) => {
                                LOG_ENABLED && console.log('keypress', event.key);
                                lastPressedKeyChar = event.key; // keypress 는 backspace, delete, enter, space, tab 등의 키를 눌렀을때 발생하지 않습니다.
                            },
                            compositionstart: (view, event) => {
                                // CJK (한글, 중국어, 일본어) 의 경우에는 조합형 입력이 시작되면 compositionstart 이벤트가 발생합니다.
                                composingStatus = IME_STATUS_START; // compositionstart가 실행되자마자 onTransaction 전에 compositionupdate 도 이어서 실행되어 composingStatus를 start로 변경하지 않습니다.
                                isStartInputIME = true;
                                currCompositionStartChar = event.data;
                                if (event.data === '') {
                                    isEmptyStartInput = true;
                                }
                                LOG_ENABLED && console.log('%ccompositionstart', 'background:blue;color:white;', event.data);
                            },
                            compositionupdate: (view, event) => {
                                composingStatus = IME_STATUS_CONTINUE;
                                currCompositionChar = event.data;
                                currCompositionUpdateChar = event.data;
                                LOG_ENABLED && console.log('%ccompositionupdate', 'background:yellow;color:red;', event.data);
                            },
                            compositionend: (view, event) => {
                                // compositionend 일때 변경되는 사항을 에디터에서 감지할 수 있게 만들거나 감지된것처럼 동작할 수 있게할 수 있는 방법이 필요
                                // 일본어 입력기능을 생각하면 이 부분을 제대로 활용해야 여러 기존의 임시방편 문제가 해결 될 수 있음
                                // 그렇게 했을때 문제가 없을 수 있는지는 또 다른 문제기는 함
                                // 간혹 compositionend 이벤트가 발생했지만 event.data 값이 없는 경우가 있음
                                composingStatus = IME_STATUS_FINISHED;
                                currCompositionChar = event.data;
                                console.log(event);
                                LOG_ENABLED && console.log('%ccompositionend', 'background:green;color:white;', event.data);

                                const { state, dispatch } = view;
                                const transaction = state.tr;
                                transaction.setMeta('trackchange-compositionend', true);
                                dispatch(transaction);
                            },
                        },
                    },
                    // #region appendTransaction
                    appendTransaction: (transactions, oldState, newState) => {
                        // onTransaction 에서는 oldState 값을 받을 수 없어서 appendTransaction에서 oldState를 저장합니다.
                        const composition = transactions[0].getMeta('composition'); // IME 입력중 발생
                        const isForcedCompositionEnd = transactions.some(transaction => transaction.getMeta('trackchange-compositionend'));
                        if (isStartInputIME) {
                            trPrevOldStateWithCompositionStart = oldState;
                            trPrevNewStateWithCompositionStart = newState;
                            if (oldState.selection.from !== oldState.selection.to) {
                                trPrevOldStateWithSelectionCompositionStart = oldState;
                                trPrevNewStateWithSelectionCompositionStart = newState;
                            }
                            LOG_ENABLED && console.log('새로운 IME 인풋이 시작되는 시점이라 trPrevOldStateWithCompositionStart 세팅', trPrevOldStateWithCompositionStart);
                        }
                        if (
                            (['Backspace', 'Enter'].includes(lastPressedKey) &&
                                transactions.some(transaction => transaction.isGeneric && transaction.steps.some(step => step instanceof ReplaceStep /*  || step instanceof ReplaceAroundStep */))) ||
                            (lastPressedKey !== 'Backspace' && transactions.some(transaction => transaction.selectionSet))
                        ) {
                            // 명시적으로 Selection 에 대한 변경이 일어났을때 현재 selection range를 알 수 있는 trOldState를 저장합니다.
                            currOldState = oldState;
                            currNewState = newState;
                            LOG_ENABLED && console.trace('변경사항이 있어서 실행될 트랜잭션', transactions);
                            LOG_ENABLED &&
                                console.table([{ oldFromPos: oldState.selection.from, oldToPos: oldState.selection.to, newFromPos: newState.selection.from, newToPos: newState.selection.to }]);
                        } else if (isForcedCompositionEnd) {
                            currOldState = oldState;
                            currNewState = newState;
                            LOG_ENABLED &&
                                console.log(
                                    '리뷰모드에서 처음줄의 마지막부터 다음줄의 끝까지 블록 지정 후 ㄱ1 을 입력해보면 1을 입력했을때 compositionend가 발생하여 transaction을 실행합니다.',
                                    transactions,
                                );
                            LOG_ENABLED &&
                                console.table([{ oldFromPos: oldState.selection.from, oldToPos: oldState.selection.to, newFromPos: newState.selection.from, newToPos: newState.selection.to }]);
                        } else if (composition && composingStatus === IME_STATUS_NORMAL && lastPressedKeyChar) {
                            // 리뷰모드에서 ㄱ1 을 입력해보면 1을 입력할때 트랙젝션이 두번 발생하면서 셀렉션 영역이 풀리는 현상이 있음
                            LOG_ENABLED && console.log('리뷰모드에서 ㄱ1 을 입력해보면 1을 입력할때 트랙젝션이 두번 발생하면서 셀렉션 영역이 풀리는 현상이 있어서 둘중 한 상황 제외');
                        } else {
                            // LOG_ENABLED && console.log('단순 커서이동');
                        }
                        return null;
                    },
                }),
            ];
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // #region onTransaction
        onTransaction({ transaction, editor }: { editor: Editor; transaction: Transaction }) {
            // 에디터와 브라우저의 기본동작상 특정 Mark 영역 사이에 입력을 진행할때 기존의 Mark를 그대로 유지한상태로 쓰기가 되기때문에 편집모드에서도 이러한 동작을 막기위해 transaction을 조작합니다.
            const isNormalInput = composingStatus === IME_STATUS_NORMAL;
            const isStartWithContinueIME = isStartInputIME && composingStatus === IME_STATUS_CONTINUE;
            const isInputtingIME = !isStartInputIME && composingStatus === IME_STATUS_CONTINUE;
            const isFinishedIME = composingStatus === IME_STATUS_FINISHED; // IME입력중 스페이스바를 누르면 compositionend가 발생하는 현상이 있음
            isStartInputIME = false;
            composingStatus = IME_STATUS_NORMAL;

            const isForcedCompositionEnd = transaction.getMeta('trackchange-compositionend');
            const isBlur = transaction.getMeta('blur');
            if (isForcedCompositionEnd) {
                LOG_ENABLED && console.log('compositionend 이벤트가 발생하여 transaction을 실행합니다.');
            } else {
                if (!currOldState || !currNewState) return; // 커서가 세팅되지 않았는데 transaction이 실행되는 경우를 막기위한 코드
                if (!transaction.docChanged) return;
                if (transaction.getMeta(META_CHANGED_TRACK_MAUALLY)) return;
                if (!transaction.steps.length) return;
                if (transaction.getMeta('history$')) return; // prosemirror 의 history transaction 을 무시하기 위한 코드
                if (transaction.getMeta('preventUpdate')) return; // onCreate 이전에 실행되거나 setContent를 실행할때 실행되는 transaction을 무시하기 위한 코드
                if (transaction.getMeta('changedBhsnReference')) return; // BhsnReferenceExtension에서 발생하는 transaction을 무시하기 위한 코드
                if (transaction.getMeta('replacedContent')) return; // 에디터의 content가 통째로 교체되는 transaction을 무시하기 위한 코드
                if (editor.isEditable === false) return; // 에디터가 뷰어모드일때는 다른 동작이 발생해도 transaction을 무시하기 위한 코드

                // ReplaceStep 또는 ReplaceAroundStep 을 포함하지 않은 경우(comment를 추가하여 Mark만 변경되었다던가)에는 track change와는 무관하여 더이상 진행하지 않습니다.
                // (RemoveMarkStep & AddMarkStep 의 경우 심플한 paragraph에서 IME 입력중 이전 mark를 삭제하거나 추가해버리는 트랜잭션이 실행되는 이상현상이 있어서 추가됨)
                if (!transaction.steps.some(step => step instanceof ReplaceStep /*  || step instanceof ReplaceAroundStep */ /* || step instanceof RemoveMarkStep || step instanceof AddMarkStep */))
                    return;
            }

            const uiEvent = transaction.getMeta('uiEvent'); // paste / drop / cut 발생
            const composition = transaction.getMeta('composition'); // IME 입력중 발생
            LOG_ENABLED && console.log('composition', composition, 'view.composing', editor.view.composing);
            isMouseMoved = false;
            const trPrevComposition = prevComposition;
            const trCompositionStartChar = currCompositionStartChar;
            const trCompositionChar = currCompositionChar;
            const trLastPressedKeyChar = lastPressedKeyChar;
            const trLastPressedKey = lastPressedKey;
            prevComposition = composition;
            currCompositionStartChar = '';
            currCompositionChar = '';
            lastPressedKeyChar = '';
            lastPressedKey = '';

            // transaction이 이미 적용된 transaction인지 확인하고 최종적으로 변경되어야할 transaction을 정합니다.
            const isThisTrApplied = transaction.before !== editor.state.tr.doc;
            let newChangeTr = isThisTrApplied ? editor.state.tr : transaction;
            // newChangeTr.setMeta('addToHistory', false);

            let isOriginalStep = false;
            let allSteps: Step[] = [];
            let finalDeletedOffset = 0;
            const trOldState = currOldState;
            const trNewState = currNewState;
            const oldFromPos = trOldState.selection.from;
            const oldToPos = trOldState.selection.to;
            const oldAnchorPos = trOldState.selection.$anchor.pos;
            const newFromPos = trNewState.selection.from;
            const newToPos = trNewState.selection.to;
            const newAnchorPos = trNewState.selection.$anchor.pos;
            const oldFromAfterNode = trOldState.selection.$from.nodeAfter;
            const isSelectedBlockRange = oldFromPos !== oldToPos;
            const isBuggyReplaceStep = oldToPos - oldFromPos === 0 && transaction.steps[0] instanceof ReplaceStep && transaction.steps[0].to - transaction.steps[0].from > 0;
            const isBuggySelectionReplaceStep = transaction.steps[0] instanceof ReplaceStep && oldToPos - oldFromPos < transaction.steps[0].to - transaction.steps[0].from;

            const isMovedOldFromPos = oldFromPos !== prevOldFromPos;
            prevOldFromPos = oldFromPos;
            prevCompositionChar = trCompositionChar;

            LOG_ENABLED && console.groupCollapsed(`transaction trCompositionChar: ${trCompositionChar} trLastPressedKeyChar: ${trLastPressedKeyChar} trLastPressedKey: ${trLastPressedKey}`);
            LOG_ENABLED && LOG_ENABLED && console.log('transaction.step', transaction.steps, 'selection', trOldState.selection);
            LOG_ENABLED &&
                console.table([
                    {
                        oldFromPos: oldFromPos,
                        oldToPos: oldToPos,
                        oldAnchorPos: oldAnchorPos,
                    },
                ]);

            if (isBuggyReplaceStep) {
                LOG_ENABLED && console.log('블록을 지정하지 않았는데 범위 pos가 나오는 버그성 isBuggyReplaceStep이 발생했습니다.');
            }
            if (isBuggySelectionReplaceStep) {
                LOG_ENABLED && console.log('블록 지정한 범위를 초과하는 범위 pos가 나오는 버그성 isBuggySelectionReplaceStep이 발생했습니다.', transaction.steps[0].from, transaction.steps[0].to);
            }

            LOG_ENABLED &&
                console.log(
                    '현 시점의 State와 트랜젝션',
                    trOldState /* 트랜젝션이 실행되기 이전의 상태 */,
                    trNewState /* 블럭영역 다 지워지고 IME 자음 입력된 상태 */,
                    editor.state /* 블럭영역 다 지워지고 IME 자음 입력된 상태 */,
                    transaction /* doc은 블럭영역 다 지워지고 IME 자음 입력된 상태는 맞는데 transaction.docs는 같은 index의 step이 적용되기 직전의 상태 */,
                );

            // IME모드에서 여러번의 transaction 이 발생하기 때문에 history에 추가하지 않음으로써 한번에 undo 할 수 있게 합니다.
            if (!isNormalInput) {
                newChangeTr.setMeta('addToHistory', false);
            }

            try {
                if (this.storage.enabled) {
                    if (isBlur) {
                        LOG_ENABLED && console.log('리뷰모드 blur 발생');
                    } else if (!isSelectedBlockRange) {
                        // #region 리뷰모드 블록미지정
                        // 블록을 지정하지 않고 타이핑을 시작했을때
                        if (trLastPressedKey === 'Backspace') {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 상태의 Backspace');
                            allSteps = [];

                            if (!composition) {
                                // 현재 커서가 text에 위치해 있을때만 이전 내용을 삭제하고 줄바꿈등의 block 동작은 기본동작을 유지하게 합니다.
                                let node;
                                let willDeleteChar = '';
                                trOldState.tr.doc.nodesBetween(oldFromPos - 1, oldFromPos, (n, pos) => {
                                    if (n.isText) {
                                        node = n;
                                        willDeleteChar = n.text?.substring(oldFromPos - pos - 1, oldFromPos - pos) || '';
                                    } else if (n.isInline) {
                                        node = n;
                                    }
                                });

                                if (node?.isText) {
                                    LOG_ENABLED && console.log('삭제한게 text임', willDeleteChar);
                                    // 단순히 text 노드를 삭제하는데 갑자기 버그스텝이 발생할 경우가 있다. 이때는 전체 롤백 후 step을 다시 적용합니다.
                                    if (isBuggySelectionReplaceStep) {
                                        editor.view.updateState(trOldState);
                                        newChangeTr = editor.state.tr;
                                        const deleteStep = new ReplaceStep(oldFromPos - 1, oldFromPos, Slice.empty, false);
                                        newChangeTr.step(deleteStep);
                                        allSteps = [deleteStep];
                                    } else {
                                        allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                                        isOriginalStep = true;
                                    }
                                } else if (node?.isInline) {
                                    allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                                    isOriginalStep = true;
                                } else {
                                    // Text노드가 아닐때는 기본적인 삭제동작을 그대로 적용합니다.
                                }
                            }
                        } else if (trLastPressedKey === 'Delete') {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 상태의 Delete');
                            allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                            isOriginalStep = true;
                        } else if (trLastPressedKey === 'Enter') {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 상태의 Enter');
                        } else if (isForcedCompositionEnd) {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 상태의 isForcedCompositionEnd', trCompositionChar);
                            if (!trCompositionChar) {
                                if (!currCompositionUpdateChar) {
                                    LOG_ENABLED && console.log('isForcedCompositionEnd 로 들어왔지만 trCompositionChar가 없고 currCompositionUpdateChar 도 없는 경우 IME 작성중 Backspace 동작');
                                    editor.view.updateState(trPrevOldStateWithCompositionStart);
                                    newChangeTr = editor.state.tr;
                                } else {
                                    LOG_ENABLED &&
                                        console.log(
                                            'isForcedCompositionEnd 로 들어왔지만 trCompositionChar가 없고 currCompositionUpdateChar는 있는 경우 IME 작성중 시작지점이 라인마지막에 위치한 상태에서 콘솔창을 클릭했을 경우',
                                        );
                                    newChangeTr.setMeta('addToHistory', true);
                                    let diffContent = trPrevOldStateWithCompositionStart.doc.cut(trPrevOldStateWithCompositionStart.selection.to - 1, trPrevOldStateWithCompositionStart.selection.to);
                                    if (!diffContent.textContent) {
                                        // 직전에 텍스트 블록이 없는 줄의 시작부분일 경우 현재 작성중이던 IME의 mark를 그대로 적용합니다.
                                        diffContent = trNewState.doc.cut(trPrevOldStateWithCompositionStart.selection.to - 1, trPrevOldStateWithCompositionStart.selection.to);
                                    }
                                    let fragment = Fragment.from(editor.state.schema.text(currCompositionUpdateChar, []));
                                    diffContent.descendants((node, pos) => {
                                        if (node.isText) {
                                            LOG_ENABLED && console.log('Mark 판별을 위한 직전 text', node.text);
                                            fragment = Fragment.from(editor.state.schema.text(currCompositionUpdateChar, node?.marks || []));
                                        }
                                    });
                                    const insertStep = new ReplaceStep(
                                        trPrevOldStateWithCompositionStart.selection.to,
                                        trPrevOldStateWithCompositionStart.selection.to,
                                        new Slice(fragment, 0, 0),
                                        false,
                                    );
                                    newChangeTr.step(insertStep);
                                    allSteps = [insertStep];
                                }
                            } else {
                                editor.view.updateState(trPrevOldStateWithCompositionStart);
                                newChangeTr = editor.state.tr;

                                // 직전에 블록을 지정한 상태에서 IME 입력을 한 경우에는 블록지정된 마지막 노드의 mark를 가져와서 적용합니다.
                                let diffContent = trPrevOldStateWithCompositionStart.doc.cut(trPrevOldStateWithCompositionStart.selection.to - 1, trPrevOldStateWithCompositionStart.selection.to);
                                if (!diffContent.textContent) {
                                    // 직전에 텍스트 블록이 없는 줄의 시작부분일 경우 현재 작성중이던 IME의 mark를 그대로 적용합니다.
                                    diffContent = trNewState.doc.cut(newToPos - 1, newToPos);
                                }
                                let fragment = Fragment.from(editor.state.schema.text(trCompositionChar, []));
                                diffContent.descendants((node, pos) => {
                                    if (node.isText) {
                                        LOG_ENABLED && console.log('Mark 판별을 위한 직전 text', node.text);
                                        fragment = Fragment.from(editor.state.schema.text(trCompositionChar, node?.marks || []));
                                    }
                                });
                                const insertStep = new ReplaceStep(trPrevOldStateWithCompositionStart.selection.to, trPrevOldStateWithCompositionStart.selection.to, new Slice(fragment, 0, 0), false);
                                newChangeTr.step(insertStep);
                                allSteps = [insertStep];
                            }
                        } else if (!trCompositionChar && trLastPressedKeyChar) {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 IME 입력이 아닌경우 숫자/영문/스페이스 입력', oldFromPos, oldToPos, oldAnchorPos);
                            // prosemirror 의 버그를 보완하기 위해 step을 전부 롤백시킨 후 지정된 블록을 삭제하고 타이핑한 내용을 직접 삽입하는 과정을 진행합니다.
                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;

                            const fragment = Fragment.from([editor.state.schema.text(trLastPressedKeyChar, transaction.steps[0].slice.content.firstChild?.marks || [])]);
                            const insertStep = new ReplaceStep(oldFromPos, oldFromPos, new Slice(fragment, 0, 0), false);
                            newChangeTr.step(insertStep);
                            allSteps = [insertStep];
                        } else if (trCompositionChar) {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 IME 입력중 케이스');
                            if (isPrevSelectionModifiedIME && transaction.steps[0].from !== oldFromPos) {
                                LOG_ENABLED && console.log('리뷰모드 직전에 블록지정한 IME 입력중 커서가 이동된 케이스(?)');
                            } else if (isPrevSelectionModifiedIME && isStartWithContinueIME) {
                                LOG_ENABLED && console.log('리뷰모드 직전에 블록지정한 IME 입력이 교체되면서 다시 compositionstart로 돌아간 케이스');
                            }
                        } else if (uiEvent === 'drop') {
                            LOG_ENABLED && console.log('리뷰모드 외부에서 컨텐츠를 드래그해서 놓은 케이스');
                            allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                            isOriginalStep = true;
                        } else if (uiEvent === 'paste') {
                            // 블록 지정 후 붙여넣기를 했을때
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 후 붙여넣기', transaction, oldFromPos, oldToPos, oldAnchorPos);
                            newChangeTr.setMeta('addToHistory', false);
                            allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                            isOriginalStep = true;
                        } else if (composition && trLastPressedKey) {
                            // 발생상황 여러줄을 선택하고 ㄱ1 을 타이핑 or 블록을 지정하고 중국어 입력할때
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 (직전에 블록지정된 상태였던) ㄱ1 입력 케이스');
                        } else {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정 ETC 입력 케이스');
                            // editor.view.updateState(trOldState);
                            // newChangeTr = editor.state.tr;
                        }

                        if (isPrevSelectionModifiedIME) {
                            LOG_ENABLED && console.log('리뷰모드 블록 미지정인데 직전에 블록지정하고 IME입력을 했었음');
                            isPrevSelectionModifiedIME = false;
                        }
                    } else {
                        // #region 리뷰모드 블록지정
                        // 블록을 지정하고 타이핑을 시작했을때
                        if (isForcedCompositionEnd) {
                            LOG_ENABLED &&
                                console.log(
                                    '리뷰모드 블록지정 상태의 isForcedCompositionEnd(ㄱ만 치고 커서이동 등의 상황)',
                                    transaction,
                                    oldFromPos,
                                    oldToPos,
                                    oldAnchorPos,
                                    newFromPos,
                                    newToPos,
                                    trCompositionChar,
                                );
                            newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos, oldToPos));
                        } else if (isNormalInput && trLastPressedKeyChar) {
                            LOG_ENABLED && console.log('리뷰모드 블록지정 상태의 숫자/영문/스페이스 입력', transaction, oldFromPos, oldToPos, oldAnchorPos);
                            // prosemirror 의 버그를 보완하기 위해 step을 전부 롤백시킨 후 지정된 블록을 삭제하고 타이핑한 내용을 직접 삽입하는 과정을 진행합니다.
                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;
                            allSteps = [];

                            newChangeTr.setMeta('addToHistory', true);

                            let fragment = Fragment.empty;
                            if (trLastPressedKeyChar) {
                                const newNode = newChangeTr.doc.nodeAt(oldToPos - 1);
                                fragment = Fragment.from([editor.state.schema.text(trLastPressedKeyChar, newNode?.marks || [])]);
                            }

                            // 직전에 블록지정된 상태로 IME 타이핑을 하면 IME타이핑중인 글자가 블록지정된 상태로 남아있게되서 composition이 존재할때는 블록을 지우지않고 타이핑한 내용을 추가합니다.
                            if (!composition) {
                                let reAddOffset = 0;
                                newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                    if (node.isInline) {
                                        const removeStep = new ReplaceStep(
                                            Math.max(oldFromPos, pos + reAddOffset),
                                            Math.min(oldToPos + reAddOffset, pos + reAddOffset + node.nodeSize),
                                            Slice.empty,
                                            false,
                                        );
                                        reAddOffset -= removeStep.to - removeStep.from;
                                        allSteps.push(removeStep);
                                    }
                                });
                                allSteps.forEach(step => newChangeTr.step(step));
                                if (trLastPressedKeyChar) {
                                    const insertStep = new ReplaceStep(allSteps[allSteps.length - 1].from, allSteps[allSteps.length - 1].from, new Slice(fragment, 0, 0), false);
                                    newChangeTr.step(insertStep);
                                    allSteps.push(insertStep);
                                    newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, allSteps[allSteps.length - 1].from + 1, allSteps[allSteps.length - 1].from + 1));
                                }
                            } else {
                                if (trLastPressedKeyChar) {
                                    const insertStep = new ReplaceStep(oldToPos, oldToPos, new Slice(fragment, 0, 0), false);
                                    newChangeTr.step(insertStep);
                                    allSteps.push(insertStep);
                                    newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos + 1, oldToPos + 1));
                                }
                            }
                        } else if (composition && trCompositionChar) {
                            LOG_ENABLED && console.log('리뷰모드 블록지정 상태의 IME 입력 시작 케이스');
                            // const inverseSteps = transaction.steps
                            //     .map((step, index) => {
                            //         return step.invert(transaction.docs[index]);
                            //     })
                            //     .reverse();
                            // inverseSteps.forEach(inverseStep => {
                            //     newChangeTr.step(inverseStep);
                            // });
                            // editor.view.updateState(editor.state.apply(newChangeTr));
                            // newChangeTr = editor.state.tr;

                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;
                            const deletionMark = newChangeTr.doc.type.schema.marks[MARK_DELETE_REVISION].create({
                                revision: {
                                    id: crypto.randomUUID(),
                                    authorInfo: this.storage.authorInfo,
                                    createdAt: dayjs().toISOString(),
                                },
                            });

                            let reAddOffset = 0;
                            const willRemoveStep: ReplaceStep[] = [];
                            newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                if (node.isInline) {
                                    const revisionMarks = node.marks.filter(m => m.attrs.revision);
                                    revisionMarks.forEach(mark => {
                                        if ([MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(mark.type.name) && mark.attrs.revision.authorInfo.id === this.storage.authorInfo.id) {
                                            // 동일인이 작성한 리비전이면 아예 삭제합니다.
                                            const removeStep = new ReplaceStep(
                                                Math.max(pos + reAddOffset, oldFromPos),
                                                Math.min(pos + node.nodeSize + reAddOffset, oldToPos + reAddOffset),
                                                Slice.empty,
                                                false,
                                            );
                                            // newChangeTr.step(removeStep);
                                            willRemoveStep.push(removeStep);
                                            finalDeletedOffset -= removeStep.to - removeStep.from;
                                            reAddOffset -= removeStep.to - removeStep.from;
                                        } else {
                                            const removeMarkStep = new RemoveMarkStep(Math.max(pos, oldFromPos), Math.min(pos + node.nodeSize, oldToPos), mark);
                                            newChangeTr.step(removeMarkStep);
                                        }
                                    });
                                    const addMarkStep = new AddMarkStep(Math.max(pos, oldFromPos), Math.min(pos + node.nodeSize, oldToPos), deletionMark);
                                    newChangeTr.step(addMarkStep);
                                }
                            });
                            willRemoveStep.forEach(step => newChangeTr.step(step));
                            newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos + reAddOffset));
                            editor.view.updateState(editor.state.apply(newChangeTr));
                            newChangeTr = editor.state.tr;

                            trPrevOldStateWithCompositionStart = editor.state;

                            // 직전에 블록을 지정한 상태에서 IME 입력을 한 경우에는 블록지정된 마지막 노드의 mark를 가져와서 적용합니다.
                            let diffContent = trOldState.doc.cut(trOldState.selection.to - 1, trOldState.selection.to);
                            if (!diffContent.textContent) {
                                // 직전에 텍스트 블록이 없는 줄의 시작부분일 경우 현재 작성중이던 IME의 mark를 그대로 적용합니다.
                                diffContent = trNewState.doc.cut(newToPos - 1, newToPos);
                            }

                            let fragment = Fragment.from(editor.state.schema.text(trCompositionChar, []));
                            diffContent.descendants((node, pos) => {
                                if (node.isText) {
                                    LOG_ENABLED && console.log('Mark 판별을 위한 직전 text', node.text);
                                    fragment = Fragment.from(editor.state.schema.text(trCompositionChar, node?.marks || []));
                                }
                            });

                            const insertStep = new ReplaceStep(oldToPos + reAddOffset, oldToPos + reAddOffset, new Slice(fragment, 0, 0), false);
                            newChangeTr.step(insertStep);
                            allSteps = [insertStep];
                            newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, allSteps[allSteps.length - 1].to + 1));
                            isPrevSelectionModifiedIME = true;
                        } else if (composition && editor.view.composing && !trCompositionChar && trLastPressedKeyChar) {
                            // 리뷰모드에서는 IME 입력 첫글자 'ㄱ' 를 입력했을때 기존의 블록지정된 부분을 지웠다가 되살리면서 커서의 위치가 바뀌가 되면서 compositionend가 발생하지 않기때문에 발생하는 예외
                            LOG_ENABLED && console.log('리뷰모드 블록지정 상태의 IME 입력중 숫자/영문/스페이스 등 입력');
                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;

                            newChangeTr.setMeta('addToHistory', false);
                            newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos));
                            const newState = editor.state.apply(newChangeTr);
                            editor.view.updateState(newState);
                            newChangeTr = editor.state.tr;

                            const newNode = newChangeTr.doc.nodeAt(oldToPos);
                            const fragment = Fragment.from([editor.state.schema.text(trLastPressedKeyChar, newNode?.marks || [])]);
                            const insertStep = new ReplaceStep(oldToPos, oldToPos, new Slice(fragment, 0, 0), false);
                            newChangeTr.step(insertStep);
                            allSteps = [insertStep];
                        } else if (uiEvent === 'paste') {
                            // 블록 지정 후 붙여넣기를 했을때
                            LOG_ENABLED && console.log('리뷰모드 블록 지정 후 붙여넣기');
                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;
                            allSteps = [];

                            let reAddOffset = 0;
                            let lastRemoveStepOffset = 0;
                            newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                if (node.isInline) {
                                    const removeStep = new ReplaceStep(
                                        Math.max(oldFromPos, pos + reAddOffset),
                                        Math.min(oldToPos + reAddOffset, pos + reAddOffset + node.nodeSize),
                                        Slice.empty,
                                        false,
                                    );
                                    lastRemoveStepOffset = Math.max(oldFromPos, pos + reAddOffset);
                                    reAddOffset -= removeStep.to - removeStep.from;
                                    newChangeTr.step(removeStep);
                                    allSteps.push(removeStep);
                                }
                            });
                            newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, lastRemoveStepOffset));
                            const insertStep = new ReplaceStep(lastRemoveStepOffset, lastRemoveStepOffset, transaction.steps[0].slice, false);
                            newChangeTr.step(insertStep);
                            allSteps.push(insertStep);
                        } else if (uiEvent === 'cut') {
                            // 블록 지정 후 잘라내기를 했을때
                            LOG_ENABLED && console.log('리뷰모드 블록 지정 후 잘라내기');
                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;
                            allSteps = [];

                            let reAddOffset = 0;
                            newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                if (node.isInline) {
                                    const removeStep = new ReplaceStep(
                                        Math.max(oldFromPos, pos - reAddOffset),
                                        Math.min(oldToPos - reAddOffset, pos - reAddOffset + node.nodeSize),
                                        Slice.empty,
                                        false,
                                    );
                                    reAddOffset += removeStep.to - removeStep.from;
                                    newChangeTr.step(removeStep);
                                    allSteps.push(removeStep);
                                }
                            });
                            newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos - reAddOffset, oldToPos - reAddOffset));
                        } else if (uiEvent === 'drop') {
                            // 블록 지정 후 마우스로 드래그해서 넣을때
                            LOG_ENABLED && console.log('리뷰모드 블록 지정 후 드래그앤 드랍');
                            editor.view.updateState(trOldState);
                            newChangeTr = editor.state.tr;
                            allSteps = [];
                            newChangeTr.setMeta('addToHistory', true);

                            if (newFromPos > oldFromPos) {
                                let reAddOffset = 0;
                                newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                    if (node.isInline) {
                                        const removeStep = new ReplaceStep(
                                            Math.max(oldFromPos, pos - reAddOffset),
                                            Math.min(oldToPos - reAddOffset, pos - reAddOffset + node.nodeSize),
                                            Slice.empty,
                                            false,
                                        );
                                        reAddOffset += removeStep.to - removeStep.from;
                                        newChangeTr.step(removeStep);
                                        allSteps.push(removeStep);
                                    }
                                });
                                // TODO ReplaceAroundStep 도 고려해야함
                                const insertStep = new ReplaceStep(newFromPos, newFromPos, transaction.steps[1].slice, transaction.steps[1].structure);
                                newChangeTr.step(insertStep);
                                allSteps.push(insertStep);
                                newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, newFromPos));
                            } else {
                                // TODO ReplaceAroundStep 도 고려해야함
                                const insertStep = new ReplaceStep(newFromPos, newFromPos, transaction.steps[1].slice, transaction.steps[1].structure);
                                newChangeTr.step(insertStep);
                                allSteps.push(insertStep);

                                let reAddOffset = 0;
                                const removeSteps: Step[] = [];
                                newChangeTr.doc.nodesBetween(oldFromPos + insertStep.slice.size, oldToPos + insertStep.slice.size, (node, pos) => {
                                    if (node.isInline) {
                                        const removeStep = new ReplaceStep(
                                            Math.max(oldFromPos + insertStep.slice.size, pos + reAddOffset),
                                            Math.min(oldToPos + insertStep.slice.size + reAddOffset, pos + reAddOffset + node.nodeSize),
                                            Slice.empty,
                                            false,
                                        );
                                        removeSteps.push(removeStep);
                                        reAddOffset -= removeStep.to - removeStep.from;
                                        newChangeTr.step(removeStep);
                                        allSteps.push(removeStep);
                                    }
                                });
                                newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, newFromPos));
                            }
                        } else if (!trCompositionChar && !isNormalInput) {
                            LOG_ENABLED &&
                                console.log(
                                    '리뷰모드 한줄의 끝에서부터 여러줄을 블록지정한 상태로 IME 첫 타이핑을 시작하면 여러줄일때 커서가 이동되는 문제 때문에 다시 셀렉션을 지정했을때 다음 타이핑이 이곳으로 오게됨',
                                );
                        } else if (isNormalInput) {
                            if (trLastPressedKey === 'Enter') {
                                LOG_ENABLED && console.log('리뷰모드 블록 지정 후 Enter');
                                editor.view.updateState(trOldState);
                                newChangeTr = editor.state.tr;
                                allSteps = [];

                                let reAddOffset = 0;
                                newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                    if (node.isInline) {
                                        const removeStep = new ReplaceStep(
                                            Math.max(oldFromPos, pos - reAddOffset),
                                            Math.min(oldToPos - reAddOffset, pos - reAddOffset + node.nodeSize),
                                            Slice.empty,
                                            false,
                                        );
                                        reAddOffset += removeStep.to - removeStep.from;
                                        newChangeTr.step(removeStep);
                                        allSteps.push(removeStep);
                                    }
                                });
                            } else if (trLastPressedKey === 'Backspace') {
                                LOG_ENABLED && console.log('리뷰모드 블록 지정 후 Backspace');
                                const deletedNode = trOldState.doc.nodeAt(oldFromPos);
                                if (oldToPos - oldFromPos === 1 && deletedNode && !deletedNode.isInline) {
                                    // 일부 커스텀 타입의 노드들은 지우기 동작시 1의 사이즈를 가지는 블록지정으로 구분됩니다.
                                    allSteps = [];
                                } else {
                                    editor.view.updateState(trOldState);
                                    newChangeTr = editor.state.tr;
                                    allSteps = [];

                                    let reAddOffset = 0;
                                    newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                        if (node.isInline) {
                                            const removeStep = new ReplaceStep(
                                                Math.max(oldFromPos, pos + reAddOffset),
                                                Math.min(oldToPos + reAddOffset, pos + reAddOffset + node.nodeSize),
                                                Slice.empty,
                                                false,
                                            );
                                            reAddOffset -= removeStep.to - removeStep.from;
                                            newChangeTr.step(removeStep);
                                            allSteps.push(removeStep);
                                            return false;
                                        }
                                    });
                                }
                            } else if (trLastPressedKey === 'Delete') {
                                LOG_ENABLED && console.log('리뷰모드 블록 지정 후 Delete');
                                const deletedNode = trOldState.doc.nodeAt(oldFromPos);
                                if (oldToPos - oldFromPos === 1 && deletedNode && !deletedNode.isInline) {
                                    // 일부 커스텀 타입의 노드들은 지우기 동작시 1의 사이즈를 가지는 블록지정으로 구분됩니다.
                                    allSteps = [];
                                } else {
                                    editor.view.updateState(trOldState);
                                    newChangeTr = editor.state.tr;
                                    allSteps = [];

                                    let reAddOffset = 0;
                                    newChangeTr.doc.nodesBetween(oldFromPos, oldToPos, (node, pos) => {
                                        if (node.isInline) {
                                            const removeStep = new ReplaceStep(
                                                Math.max(oldFromPos, pos - reAddOffset),
                                                Math.min(oldToPos - reAddOffset, pos - reAddOffset + node.nodeSize),
                                                Slice.empty,
                                                false,
                                            );
                                            reAddOffset += removeStep.to - removeStep.from;
                                            newChangeTr.step(removeStep);
                                            allSteps.push(removeStep);
                                        }
                                    });
                                    newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos - reAddOffset, oldToPos - reAddOffset));
                                }
                            } else {
                                LOG_ENABLED && console.log('리뷰모드 블록 지정 후 NormalInput 의 ETC 상황', trLastPressedKey);
                                // 한줄의 처음부터 끝까지 블록 지정 후 ㄱ1 을 입력해보면 중간에 이 단계가 발생합니다.
                                // editor.view.updateState(trOldState);
                                // newChangeTr = editor.state.tr;
                                // allSteps = [];
                                // allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                                // isOriginalStep = true;
                            }
                        } else {
                            LOG_ENABLED && console.log('리뷰모드 블록지정 ETC 입력 케이스');
                        }
                    }
                } else {
                    // 편집모드일때
                    // 블록을 지정하지 않고 타이핑을 시작했을때
                    if (!isSelectedBlockRange) {
                        // #region 편집모드 블록미지정
                        if (trLastPressedKey === 'Backspace') {
                            LOG_ENABLED && console.log('편집모드 블록 미지정 상태의 Backspace');
                        } else if (trLastPressedKey === 'Delete') {
                            LOG_ENABLED && console.log('편집모드 블록 미지정 상태의 Delete');
                        } else if (trLastPressedKey === 'Enter') {
                            LOG_ENABLED && console.log('편집모드 블록 미지정 상태의 Enter');
                        } else if (isForcedCompositionEnd) {
                            LOG_ENABLED && console.log('편집모드 블록 미지정 상태의 isForcedCompositionEnd', trCompositionChar);
                            if (trPrevOldStateWithCompositionStart.selection.from !== trPrevOldStateWithCompositionStart.selection.to) {
                                // 타이핑 시작할때 블록되었던 영역이 있는 상태였다면
                                LOG_ENABLED && console.log('직전에 블록되어있던 영역 from, to', trPrevOldStateWithCompositionStart.selection.from, trPrevOldStateWithCompositionStart.selection.to);
                                editor.view.updateState(trPrevOldStateWithCompositionStart);
                                newChangeTr = editor.state.tr;

                                // 직전에 블록을 지정한 상태에서 IME 입력을 한 경우에는 블록지정된 마지막 노드의 mark를 가져와서 적용합니다.
                                let diffContent = trPrevOldStateWithCompositionStart.doc.cut(trPrevOldStateWithCompositionStart.selection.to - 1, trPrevOldStateWithCompositionStart.selection.to);
                                if (!diffContent.textContent) {
                                    // 직전에 텍스트 블록이 없는 줄의 시작부분일 경우 현재 작성중이던 IME의 mark를 그대로 적용합니다.
                                    diffContent = trNewState.doc.cut(newToPos - 1, newToPos);
                                }

                                if (trCompositionChar) {
                                    // IME 입력 도중에 Backspace 입력시 trCompositionChar 이 비어있을 수 있다
                                    let fragment = Fragment.from(editor.state.schema.text(trCompositionChar, []));
                                    diffContent.descendants((node, pos) => {
                                        if (node.isText) {
                                            LOG_ENABLED && console.log('Mark 판별을 위한 직전 text', node.text);
                                            fragment = Fragment.from(editor.state.schema.text(trCompositionChar, node?.marks || []));
                                        }
                                    });
                                    const insertStep = new ReplaceStep(
                                        trPrevOldStateWithCompositionStart.selection.from,
                                        trPrevOldStateWithCompositionStart.selection.from,
                                        new Slice(fragment, 0, 0),
                                        false,
                                    );
                                    allSteps = [insertStep];
                                }

                                newChangeTr.replace(trPrevOldStateWithCompositionStart.selection.from, trPrevOldStateWithCompositionStart.selection.to, Slice.empty);
                                editor.view.updateState(editor.state.apply(newChangeTr));
                                newChangeTr = editor.state.tr;

                                allSteps.forEach(step => newChangeTr.step(step));
                            } else {
                                // 블록지정 없이 타이핑 할때
                                editor.view.updateState(trPrevOldStateWithCompositionStart);
                                newChangeTr = editor.state.tr;

                                const end = newChangeTr.doc.content.findDiffEnd(trNewState.doc.content);
                                if (end) {
                                    const diffContent = trNewState.doc.cut(end.a, end.a + trCompositionChar.length);
                                    let fragment = Fragment.empty;
                                    diffContent.descendants((node, pos) => {
                                        if (node.isText) {
                                            fragment = Fragment.from(editor.state.schema.text(trCompositionChar, node?.marks || []));
                                        }
                                    });
                                    const insertStep = new ReplaceStep(end.a, end.a, new Slice(fragment, 0, 0), false);
                                    newChangeTr.step(insertStep);
                                    allSteps = [insertStep];
                                }
                            }
                        } else if (!composition && !editor.view.composing && trLastPressedKeyChar) {
                            LOG_ENABLED && console.log('편집모드 IME 입력이 아닌경우 숫자/영문/스페이스 입력');
                            const inverseSteps = transaction.steps
                                .map((step, index) => {
                                    return step.invert(transaction.docs[index]);
                                })
                                .reverse();
                            inverseSteps.forEach(inverseStep => {
                                newChangeTr.step(inverseStep);
                            });
                            editor.view.updateState(editor.state.apply(newChangeTr));
                            newChangeTr = editor.state.tr;

                            const fragment = Fragment.from([editor.state.schema.text(trLastPressedKeyChar, transaction.steps[0].slice.content.firstChild?.marks || [])]);
                            const insertStep = new ReplaceStep(oldFromPos, oldFromPos, new Slice(fragment, 0, 0), false);
                            newChangeTr.step(insertStep);
                            allSteps = [insertStep];
                        } else if (composition && editor.view.composing && trCompositionChar) {
                            LOG_ENABLED && console.log('편집모드 블록 미지정 IME 입력중 케이스');
                        } else if (uiEvent === 'drop') {
                            LOG_ENABLED && console.log('편집모드 외부에서 컨텐츠를 드래그해서 놓은 케이스');
                            allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                            isOriginalStep = true;
                        } else {
                            LOG_ENABLED && console.log('편집모드 블록 미지정 ETC 입력 케이스');
                        }

                        if (isPrevSelectionModifiedIME) {
                            LOG_ENABLED && console.log('편집모드 블록 미지정인데 직전에 블록지정하고 IME입력을 했었음');
                            isPrevSelectionModifiedIME = false;
                        }
                    } else {
                        // #region 편집모드 블록지정
                        if (composition && editor.view.composing && trCompositionChar) {
                            LOG_ENABLED && console.log('편집모드 블록지정 상태의 IME 입력 시작 케이스');
                            isPrevSelectionModifiedIME = true;
                        } else if (isForcedCompositionEnd) {
                            // 블록지정 후 ㄱ1 입력시 상황
                            LOG_ENABLED && console.log('편집모드 블록지정 상태의 IME compositionend 케이스');
                            editor.view.updateState(trPrevOldStateWithCompositionStart);
                            newChangeTr = editor.state.tr;

                            const end = trPrevOldStateWithCompositionStart.doc.content.findDiffEnd(trNewState.doc.content);
                            if (end) {
                                const diffContent = trNewState.doc.cut(end.a, end.a + trCompositionChar.length);
                                let fragment = Fragment.empty;
                                diffContent.descendants((node, pos) => {
                                    if (node.isText) {
                                        fragment = Fragment.from(editor.state.schema.text(trCompositionChar, node?.marks || []));
                                    }
                                });
                                const insertStep = new ReplaceStep(end.a, end.a, new Slice(fragment, 0, 0), false);
                                newChangeTr.step(insertStep);
                                allSteps = [insertStep];
                            }
                        } else {
                            LOG_ENABLED && console.log('편집모드 블록지정 ETC 케이스');
                            allSteps = transaction.steps.map(step => Step.fromJSON(editor.state.doc.type.schema, step.toJSON()));
                            isOriginalStep = true;
                        }
                    }
                }

                LOG_ENABLED && console.log('allSteps', allSteps);
                // ReplaceStep 을 포함하지 않은 경우에는 더이상 진행하지 않습니다.
                if (allSteps.some(step => step instanceof ReplaceStep || step instanceof ReplaceAroundStep)) {
                    const lastFixedDocs = (isOriginalStep ? transaction : newChangeTr).docs.map(doc => doc.copy(doc.content));
                    LOG_ENABLED && console.log('lastFixedDocs', lastFixedDocs);

                    let reAddOffset = 0;
                    const defaultInsertionMark = editor.state.doc.type.schema.marks[MARK_INSERT_REVISION].create();
                    const defaultDeletionMark = editor.state.doc.type.schema.marks[MARK_DELETE_REVISION].create();
                    const defaultMoveFromMark = editor.state.doc.type.schema.marks[MARK_MOVE_FROM_REVISION].create();
                    const defaultMoveToMark = editor.state.doc.type.schema.marks[MARK_MOVE_TO_REVISION].create();
                    let deletionMark: Mark;
                    allSteps.forEach((step: Step, index: number) => {
                        if (step instanceof ReplaceStep /*  || step instanceof ReplaceAroundStep */ /*  || step instanceof ReplaceAroundStep // indent 같은 동작을 할때 ReplaceAroundStep이 발생 */) {
                            if (step.slice.size) {
                                const from = step.from + reAddOffset;
                                const to = step.from + reAddOffset + (step instanceof ReplaceAroundStep ? step.slice.size - 2 : step.slice.size);
                                let currentNode;
                                lastFixedDocs[index].nodesBetween(step.from - 1, step.from, (node, pos) => {
                                    if (!currentNode && node.isText) {
                                        currentNode = node;
                                        return false;
                                    }
                                });
                                const currentMarks = currentNode?.marks;
                                if (this.storage.enabled) {
                                    // 현재 포지션 위치가 insertionMark가 있는 위치라면, 현재 같은 작성자인지 확인하여, insertionMark의 revision을 이전과 동일한 revision으로 insertionMark를 생성합니다.
                                    let currentInsertionRevisionMark;
                                    let insertionMark;
                                    if (currentMarks) {
                                        currentInsertionRevisionMark = currentMarks.find(
                                            m => [MARK_INSERT_REVISION].includes(m.type.name) && m.attrs.revision.authorInfo.id === this.storage.authorInfo.id,
                                        );
                                        const revisionMarks = currentMarks.filter(m => m.attrs.revision);
                                        revisionMarks.forEach(mark => {
                                            newChangeTr.step(new RemoveMarkStep(from, to, mark));
                                        });
                                    }
                                    if (currentInsertionRevisionMark) {
                                        insertionMark = currentInsertionRevisionMark;
                                    } else {
                                        insertionMark = editor.state.doc.type.schema.marks[MARK_INSERT_REVISION].create({
                                            revision: {
                                                id: crypto.randomUUID(),
                                                authorInfo: this.storage.authorInfo,
                                                createdAt: dayjs().toISOString(),
                                            },
                                        });
                                    }

                                    newChangeTr.step(new AddMarkStep(from, to, insertionMark));
                                    // // 태그가 중복되어 있을때 원래 위치에 중첩된 내용이 있으면 태그 전체를 step영역으로 잡는 문제가 발생한다.

                                    // 추가된 mark가 직전에 있던 mark와 합쳐진 영역이거나 붙여넣기한 영역일때 이 합쳐진 mark의 revision.createdAt을 현재시간으로 바꿔주기 위해 합쳐진 영역의 from, to를 찾아서 createdAt을 바꿔줍니다.
                                    if (currentInsertionRevisionMark) {
                                        const updatedInsertionMark = editor.state.doc.type.schema.marks[MARK_INSERT_REVISION].create({
                                            revision: {
                                                id: crypto.randomUUID(),
                                                authorInfo: this.storage.authorInfo,
                                                createdAt: dayjs().toISOString(),
                                            },
                                        });
                                        newChangeTr.doc.nodesBetween(from - 1, from + 1, (node, pos) => {
                                            // currentInsertionRevisionMark 노드의 위치를 찾습니다.
                                            const fromPos = pos;
                                            const toPos = pos + node.nodeSize;
                                            if (node.isText) {
                                                // 현재 node가 currentInsertionRevisionMark 를 포함하는 node인지 확인하고, 포함한다면 from, to를 저장합니다.
                                                const hasMark = node.marks.some(mark => mark === currentInsertionRevisionMark);
                                                if (hasMark) {
                                                    newChangeTr.addMark(fromPos, toPos, updatedInsertionMark);
                                                    return false;
                                                }
                                            }
                                        });
                                    }
                                } else {
                                    newChangeTr.removeMark(from, to, defaultInsertionMark.type);
                                }
                                newChangeTr.removeMark(from, to, defaultDeletionMark.type);
                                newChangeTr.removeMark(from, to, defaultMoveFromMark.type);
                                newChangeTr.removeMark(from, to, defaultMoveToMark.type);
                            }

                            if (step.from !== step.to && this.storage.enabled) {
                                const invertedStep = lastFixedDocs.length ? step.invert(lastFixedDocs[index]) : step.invert(transaction.docs[index]);
                                LOG_ENABLED && console.log('다시 추가해야할 컨텐츠가 있음', step);
                                const skipSteps: Array<ReplaceStep> = [];
                                LOG_ENABLED && console.log('invertedStep', invertedStep);
                                let reAddStep;
                                if (invertedStep instanceof ReplaceStep) {
                                    reAddStep = new ReplaceStep(invertedStep.from + reAddOffset, invertedStep.to + reAddOffset, invertedStep.slice, invertedStep.structure);
                                } else if (invertedStep instanceof ReplaceAroundStep) {
                                    reAddStep = new ReplaceAroundStep(
                                        invertedStep.from + reAddOffset,
                                        invertedStep.to + reAddOffset,
                                        invertedStep.gapFrom,
                                        invertedStep.gapTo,
                                        invertedStep.slice,
                                        invertedStep.insert,
                                        invertedStep.structure,
                                    );
                                }
                                let addedEmptyOffset = 0;
                                const travelContent = (content: Fragment, parentOffset: number) => {
                                    content.forEach((node, offset) => {
                                        const start = parentOffset + offset;
                                        const end = start + node.nodeSize;
                                        if (node.content && node.content.size) {
                                            // 이 노드에는 하위 콘텐츠가 있으므로 이동
                                            travelContent(node.content, start);
                                        } else {
                                            // 이 노드는 텍스트 노드이거나 하위 콘텐츠가 없는 노드이므로 처리
                                            const revisionMarks = node.marks.filter(m => m.attrs.revision);
                                            if (!revisionMarks.length) return;
                                            const latestRevisionMark = revisionMarks.reduce((prev, current) =>
                                                dayjs(prev.attrs.revision.createdAt) > dayjs(current.attrs.revision.createdAt) ? prev : current,
                                            );
                                            if (
                                                [MARK_INSERT_REVISION, MARK_MOVE_TO_REVISION].includes(latestRevisionMark.type.name) &&
                                                latestRevisionMark.attrs.revision.authorInfo.id === this.storage.authorInfo.id
                                            ) {
                                                // 리뷰모드에서 삭제를 진행할때 레드라인이 아니라 실제로 삭제되어야 하는 영역에 대한 단계를 구성하고 readd 작업이 적용된 후 이를 적용합니다.
                                                skipSteps.push(new ReplaceStep(start - addedEmptyOffset, end - addedEmptyOffset, Slice.empty));
                                                addedEmptyOffset += node.nodeSize;
                                                finalDeletedOffset -= node.nodeSize;
                                                reAddOffset -= node.nodeSize;
                                            }
                                        }
                                    });
                                };
                                travelContent(invertedStep.slice.content, invertedStep.from + reAddOffset);
                                reAddOffset += invertedStep.slice.size;

                                // step을 재적용합니다.
                                newChangeTr.step(reAddStep);
                                const { from } = reAddStep;
                                const to = from + reAddStep.slice.size;

                                if (!deletionMark) {
                                    // 다시 추가한 컨텐츠를 위해 delete Mark를 적용합니다.
                                    let nextNode: _Node | null = null;
                                    let nextNodeMarks: Mark[] = [];
                                    let nextNodePos = 0;

                                    if (!isSelectedBlockRange && trLastPressedKey === 'Delete') {
                                        // block을 지정하지 않은 상태에서 Delete를 눌렀을때는 커서가 뒤로 이동하면서 삭제가 되기 때문에 예외적으로 이전노드를 바라봐야한다.
                                        newChangeTr.doc.nodesBetween(oldToPos - 10, oldToPos, (node, pos) => {
                                            if (node.isText) {
                                                nextNode = node;
                                                nextNodeMarks = [...node.marks];
                                                nextNodePos = pos;
                                                return false;
                                            }
                                        });
                                    } else if (!isSelectedBlockRange && trLastPressedKey === 'Backspace') {
                                        newChangeTr.doc.nodesBetween(oldToPos, oldToPos + 1, (node, pos) => {
                                            if (!nextNode && node.isText) {
                                                nextNode = node;
                                                nextNodeMarks = [...node.marks];
                                                nextNodePos = pos;
                                                return false;
                                            }
                                        });
                                    }
                                    let currentDeletionRevisionMark;
                                    if (nextNode && nextNodeMarks) {
                                        currentDeletionRevisionMark = nextNodeMarks.find(
                                            m => [MARK_DELETE_REVISION].includes(m.type.name) && m.attrs.revision.authorInfo.id === this.storage.authorInfo.id,
                                        );
                                    }
                                    // 현재 포지션 위치가 삭제처리가 진행되고있던중이라면, 현재 포지션의 요소가 같은 작성자인지 확인하여, revision을 이전과 동일하게 처리합니다.
                                    if (currentDeletionRevisionMark) {
                                        const updatedDeletionMark = newChangeTr.doc.type.schema.marks[MARK_DELETE_REVISION].create({
                                            revision: {
                                                ...cloneDeep(currentDeletionRevisionMark.attrs.revision),
                                                createdAt: dayjs().toISOString(),
                                            },
                                        });
                                        deletionMark = updatedDeletionMark;
                                        newChangeTr.addMark(nextNodePos, nextNodePos + nextNode!.nodeSize, deletionMark);
                                    } else {
                                        deletionMark = newChangeTr.doc.type.schema.marks[MARK_DELETE_REVISION].create({
                                            revision: {
                                                id: crypto.randomUUID(),
                                                authorInfo: this.storage.authorInfo,
                                                createdAt: dayjs().toISOString(),
                                            },
                                        });
                                    }
                                }
                                newChangeTr.addMark(from, to, deletionMark);
                                newChangeTr.removeMark(from, to, defaultInsertionMark.type);
                                newChangeTr.removeMark(from, to, defaultMoveFromMark.type);
                                newChangeTr.removeMark(from, to, defaultMoveToMark.type);

                                skipSteps.forEach(step => {
                                    newChangeTr.step(step);
                                });
                            }
                        }
                    });

                    if (this.storage.enabled && !isSelectedBlockRange && trLastPressedKey === 'Backspace' && oldFromPos > 1) {
                        newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, newFromPos));
                    } else if (isSelectedBlockRange && trLastPressedKey === 'Backspace') {
                        // newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldFromPos));
                    } else if (this.storage.enabled && isSelectedBlockRange && isNormalInput && trLastPressedKey === 'Enter') {
                        newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos));
                    } else if (this.storage.enabled && isSelectedBlockRange && composition && editor.view.composing && !trCompositionChar && trLastPressedKeyChar) {
                        // 리뷰모드에서 ㄱ123 을 할때 ㄱ1 ㄱ2 ㄱ3 으로 바뀌는 현상을 막기위해 추가
                        // LOG_ENABLED && console.log('리뷰모드 블록지정 상태의 숫자/영문/스페이스 입력중 케이스가 마무리되고 커서의 위치를 이동합니다.', oldToPos + 1);
                    } else if (this.storage.enabled && composition && editor.view.composing && trCompositionChar) {
                        // LOG_ENABLED && console.log('리뷰모드 블록지정 상태의 IME 입력중 케이스가 마무리되고 커서의 위치를 이동합니다.', oldToPos + 1);
                    } else if (this.storage.enabled && isSelectedBlockRange && composition && trCompositionChar) {
                        // newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, oldToPos));
                    }
                }
                editor.view.updateState(editor.state.apply(newChangeTr));
                // if (this.storage.enabled && isSelectedBlockRange && composition && trCompositionChar) {
                //     LOG_ENABLED && console.log('리뷰모드 블록지정 상태의 IME가 시작되면 블록지정을 해제해줍니다.');
                //     editor.view.updateState(editor.state.apply(editor.state.tr.setSelection(TextSelection.create(editor.state.tr.doc, oldToPos + finalDeletedOffset + 1))));
                // }
                //  else if (!this.storage.enabled && isForcedCompositionEnd) {
                //     editor.view.updateState(editor.state.apply(editor.state.tr.setSelection(TextSelection.create(editor.state.tr.doc, trPrevOldStateWithCompositionStart.selection.from + 1))));
                // }
                newChangeTr = editor.state.tr;
            } catch (err) {
                console.error(err);
                Sentry.captureException(err);
                console.warn('레드라인 동작중 에러가 발생하여 동작을 롤백합니다');
                editor.view.updateState(trOldState);
            }
            // editor.view.updateState(editor.state.apply(editor.state.tr));
            LOG_ENABLED && console.groupEnd();
        },
    });
    return wordTrackChangeExtensionInstance;
}

export { InsertRevision, DeleteRevision, FormatRevision, MoveFromRevision, MoveToRevision };
export { MARK_INSERT_REVISION, MARK_DELETE_REVISION, MARK_FORMAT_REVISION, MARK_MOVE_FROM_REVISION, MARK_MOVE_TO_REVISION };
