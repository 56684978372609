import type { BhsnBlockReferenceItem, BhsnReferenceOptions } from './BhsnBlockReferenceExtension';
import { Node, mergeAttributes } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';

export const BhsnBlockReferenceUnlinkExtension = Node.create<BhsnReferenceOptions>({
    name: 'blockReference',
    group: 'block',
    content: 'block*',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-block-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-block-reference', mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        return {
            updateBlockReferenceContent:
                attributes =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema, apply } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    const ids = attributes.map(attr => attr.id);
                    const nodeInfos: any[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType && ids.includes(node.attrs['data-id'])) {
                            nodeInfos.push({ node, pos });
                        }
                    });

                    // Process nodes in reverse order to avoid the position out of range issue
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { id, value } = attributes.find(attr => attr.id === node.attrs['data-id']) || {};

                        if (id) {
                            let fragment;
                            if (value) {
                                if (typeof value === 'object') {
                                    fragment = Fragment.fromJSON(schema, value);
                                } else {
                                    fragment = Fragment.fromJSON(schema, [
                                        {
                                            type: 'paragraph',
                                            content: [{ type: 'text', text: value }],
                                        },
                                    ]);
                                }
                            } else {
                                fragment = Fragment.empty;
                            }

                            const updatedNode = node.copy(fragment);
                            tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                        }
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 block reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputBlockReferenceContent:
                (id, scrollInto = false, scrollIntoOption = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    let blockReferenceNodePosition: number | null = null;

                    // 선택한 reference 노드에만 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            if (id && id === node.attrs['data-id']) {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': true });
                                if (blockReferenceNodePosition === null) blockReferenceNodePosition = pos;
                            } else {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': undefined });
                            }
                        }
                    });

                    if (scrollInto && blockReferenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(blockReferenceNodePosition);
                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(scrollIntoOption);
                            });
                        }
                    }
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 block reference를 focus했을때 해당 reference로 스크롤합니다.
            scrollIntoFocusedInputBlockReferenceContent:
                (id, option = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state }) => {
                    if (!id) return false;
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    let blockReferenceNodePosition: number | null = null;

                    doc.descendants((node, pos) => {
                        if (!blockReferenceNodePosition && node.type === blockReferenceNodeType && id === node.attrs['data-id']) {
                            blockReferenceNodePosition = pos;
                            return false;
                        }
                    });

                    if (blockReferenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(blockReferenceNodePosition);

                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(option);
                            });
                        }
                    }
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputBlockReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getBhsnBlockReferenceItems:
                (resolveCallback = () => {}) =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.blockReference;
                    const references: BhsnBlockReferenceItem[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            references.push({
                                id: node.attrs['data-id'],
                                name: node.attrs['data-name'],
                                content: node.content,
                                size: node.content.size,
                                text: node.textContent,
                            });
                        }
                    });

                    resolveCallback(references);
                    return true;
                },
        };
    },
});
