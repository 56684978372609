import { Extension } from '@tiptap/core';
// import StarterKit from '@tiptap/starter-kit';
import WordPageBreak from '../extension-page-break';
import Table from '@tiptap/extension-table';
// TODO: 테이블 관련 기능은 아직 미완성이라 사용하지 않음, 존재하는 table extension의 경우 이슈가 존재 https://github.com/frappe/wiki/issues/164
// import Table from '../extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextStyle from '../extension/TextStyle';
import FontFamily from '../extension/FontFamily';
import Color from '../extension/Color';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import {
    WordDocument,
    WordParagraph,
    LineSpacing,
    WordExtras,
    WordBookmarkNode,
    WordHyperlinkNode,
    WordToc,
    WordSection,
    WordBody,
    WordHeaderFooter,
    WordPageNumber,
    WordImage,
    WordListItem,
    WordBulletList,
    WordOrderedList,
    WordUniqueId,
} from '.';
import WordIndent from '../extension-word-indent';
import { InsertedText, DeletedText } from '../extension/Diff';
import TabKeyPrevent from '../extension/TabKeyPrevent';
import { createCommentMarkInstance } from '../extension-comment/Comment';
import { createHighlightExtensionInstance } from '../extension/Highlight';
import { createWordTrackChangeExtensionInstance } from '../extension-word-track-change';

export interface WordStarterPackOptions {
    [key: string]: any;
}

function ptToPx(pt: number) {
    return (pt * 96) / 72;
}

const WordStarterPack = Extension.create<WordStarterPackOptions>({
    name: 'wordStarterPack',

    addExtensions() {
        const extensions = [
            TabKeyPrevent,
            WordDocument,
            WordSection,
            WordBody,
            WordHeaderFooter,
            WordPageBreak,
            WordParagraph.configure({
                HTMLAttributes: {
                    class: 'm-0 min-h-[1em] whitespace-pre-wrap',
                },
            }),
            Dropcursor,
            Gapcursor,
            History,
            Text,
            TextStyle,
            FontFamily,
            Color,
            Bold,
            Italic,
            Strike.extend({
                parseHTML() {
                    return [
                        {
                            tag: 's',
                        },
                        {
                            tag: 'strike',
                        },
                        {
                            style: 'text-decoration',
                            consuming: false,
                            getAttrs: style => ((style as string).includes('line-through') ? {} : false),
                        },
                    ];
                },
            }),
            Underline,
            Subscript,
            Superscript,
            // Code,
            TextAlign.configure({
                types: ['heading', 'paragraph', 'bulletList', 'orderedList'],
            }),
            Table.configure({
                resizable: true,
                HTMLAttributes: {
                    class: 'relative border-collapse table-fixed m-0 overflow-hidden max-w-full',
                },
            }),
            TableRow.configure({
                HTMLAttributes: {
                    class: 'relative',
                },
            }),
            TableHeader.configure({
                HTMLAttributes: {
                    class: 'relative',
                },
            }),
            TableCell.extend({
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        colwidth: {
                            default: null,
                            parseHTML: element => {
                                const colwidth = element.getAttribute('colwidth');
                                const value = colwidth ? [parseInt(colwidth, 10)] : null;
                                return value;
                            },
                            renderHTML: attributes => {
                                return {
                                    style: `width: ${ptToPx(attributes.colwidth)}px;`, // TODO: 편집시 drag 기능이 상위 colgroup 의 width 를 무시하고 자신의 width 를 따라가는 문제가 있음
                                    colwidth: attributes.colwidth,
                                };
                            },
                        },
                    };
                },
            }).configure({
                HTMLAttributes: {
                    class: 'relative border border-gray-300 px-1',
                },
            }),
            WordBulletList,
            WordOrderedList,
            WordListItem,
            LineSpacing,
            WordIndent,
            HardBreak,
            InsertedText.configure({
                HTMLAttributes: {
                    class: 'bg-blue-200 text-blue-700 no-underline p-1',
                },
            }),
            DeletedText.configure({
                HTMLAttributes: {
                    class: 'bg-red-200 text-red-700 line-through p-1',
                },
            }),
            createCommentMarkInstance().configure({
                HTMLAttributes: {
                    class: 'word-comment',
                },
                onCommentActivated: commentId => {
                    // console.log('commentId', commentId);
                },
            }),
            // HighlightExtension,
            createHighlightExtensionInstance(),
            WordToc,
            WordHyperlinkNode,
            WordBookmarkNode,
            createWordTrackChangeExtensionInstance(),
            WordExtras,
            WordPageNumber,
            WordImage,
            WordUniqueId,
        ];
        return extensions;
    },
});

export default WordStarterPack;
