import { Node, mergeAttributes } from '@tiptap/core';
import type { Node as _Node } from '@tiptap/pm/model';
import { isEmpty } from 'lodash-es';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

const createDividerDecoration = (doc: _Node) => {
    const decorations: Decoration[] = [];
    const sectionNodes: number[] = []; // section 노드의 위치를 저장할 배열

    // 문서를 순회하며 section 노드의 위치를 찾습니다.
    doc.descendants((node, pos) => {
        if (node.type.name === 'section') {
            sectionNodes.push(pos); // 노드의 시작 부분을 저장
            return false;
        }
    });

    sectionNodes.shift();

    // 각 section 노드의 시작 부분에 구분선 Decoration을 추가합니다.
    sectionNodes.forEach(pos => {
        const decoration = Decoration.widget(
            pos,
            () => {
                const divider = document.createElement('div');
                divider.className = 'h-16 w-full';
                return divider;
            },
            { side: -1 },
        ); // side: -1은 노드 앞에 위젯을 배치합니다.
        decorations.push(decoration);
    });

    return DecorationSet.create(doc, decorations);
};

export const WordSection = Node.create({
    name: 'section',
    group: 'section',
    content: 'headerFooter* body headerFooter*',
    selectable: false,
    allowGapCursor: false,
    addAttributes() {
        return {
            pageSetup: {
                default: null,
                parseHTML: element => JSON.parse(element.dataset.pageSetup || 'null') || null,
                renderHTML: attributes => {
                    let pageSetupStyle = {
                        style: 'padding: 4rem;',
                    };
                    if (!isEmpty(attributes.pageSetup)) {
                        pageSetupStyle = {
                            style:
                                `${attributes.pageSetup.pageWidth ? `width: ${attributes.pageSetup.pageWidth};` : 'width: 38rem;max-width: 38rem;'}` +
                                `${attributes.pageSetup.leftMargin ? `padding-left: ${attributes.pageSetup.leftMargin};` : 'padding-left: 4rem;'}` +
                                `${attributes.pageSetup.rightMargin ? `padding-right: ${attributes.pageSetup.rightMargin};` : 'padding-right: 4rem;'}` +
                                `${attributes.pageSetup.topMargin ? `padding-top: ${attributes.pageSetup.topMargin};` : 'padding-top: 4rem;'}` +
                                `${attributes.pageSetup.bottomMargin ? `padding-bottom: ${attributes.pageSetup.bottomMargin};` : 'padding-bottom: 4rem;'}`,
                        };
                    }
                    return {
                        'data-page-setup': JSON.stringify(attributes.pageSetup),
                        ...pageSetupStyle,
                    };
                },
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'section',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['section', mergeAttributes({ class: 'bg-white border border-gray-200 min-h-[1rem]' }, HTMLAttributes), 0];
    },
    addProseMirrorPlugins() {
        return [
            new Plugin({
                state: {
                    init(_, { doc }) {
                        return createDividerDecoration(doc);
                    },
                    apply(tr, oldState) {
                        // 문서가 변경되면 Decoration을 다시 계산합니다.
                        return tr.docChanged ? createDividerDecoration(tr.doc) : oldState;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});
