import { Node, mergeAttributes } from '@tiptap/core';
import { SvelteNodeViewRenderer } from 'svelte-tiptap';
import PageBreak from './PageBreak.svelte';

export const WordPageBreak = Node.create({
    name: 'pageBreak',
    group: 'pageBreak',
    atom: true,
    draggable: true,
    selectable: true,
    inline: false,
    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    parseHTML() {
        return [{ tag: 'div.page-break' }];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ class: 'page-break' }, this.options.HTMLAttributes, HTMLAttributes)];
    },

    addNodeView() {
        return SvelteNodeViewRenderer(PageBreak);
    },
});
