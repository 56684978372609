import dayjs from 'dayjs';
import type { WordTrackChangeRevisionData } from './types';
import { Mark, mergeAttributes } from '@tiptap/core';

export const MARK_MOVE_TO_REVISION = 'moveToRevision';

// 이동 리비전 마크 (이동 끝)
const MoveToRevision = Mark.create({
    name: MARK_MOVE_TO_REVISION,
    addAttributes() {
        const defaultRevision: WordTrackChangeRevisionData = {
            id: crypto.randomUUID(),
            authorInfo: {
                id: null,
                displayName: 'Unnamed',
            },
            createdAt: dayjs().toISOString(),
        };
        return {
            revision: {
                default: defaultRevision,
                parseHTML: element => JSON.parse(element.dataset.revision || 'null') || defaultRevision,
                renderHTML: attributes => {
                    return {
                        'data-revision': JSON.stringify(attributes.revision),
                    };
                },
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'span[data-move-to-revision]',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(HTMLAttributes, { 'data-move-to-revision': '' }), 0];
    },
});

export default MoveToRevision;
