import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';

export type FontFamilyOptions = {
    types: string[];
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontFamily: {
            /**
             * Set the font family
             */
            setFontFamily: (fontFamily: string) => ReturnType;
            /**
             * Unset the font family
             */
            unsetFontFamily: () => ReturnType;
        };
    }
}

const FontFamily = Extension.create<FontFamilyOptions>({
    name: 'fontFamily',

    addOptions() {
        return {
            types: ['textStyle'],
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontFamily: {
                        default: null,
                        parseHTML: element => element.style.fontFamily?.replace(/['"]+/g, '') || null,
                        renderHTML: attributes => {
                            if (!attributes.fontFamily) {
                                return {};
                            }

                            return {
                                style: `font-family: ${attributes.fontFamily
                                    .split(',')
                                    .map((fontFamily: string) => CSS.escape(fontFamily.trim()))
                                    .join(', ')}`,
                            };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setFontFamily:
                fontFamily =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontFamily }).run();
                },
            unsetFontFamily:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontFamily: null }).removeEmptyTextStyle().run();
                },
        };
    },
});

export default FontFamily;
