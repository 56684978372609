import { Node, mergeAttributes } from '@tiptap/core';
// import { toggleWrap } from '@tiptap/commands';

const InsertedText = Node.create({
    name: 'inserted_text',
    priority: 1000,
    group: 'inline',
    inline: true,
    content: 'inline*',

    parseHTML() {
        return [
            {
                tag: 'ins',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['ins', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    // TODO: toggleWrap 함수를 사용할수가 없음. 방법 찾아서 적용해야함.
    //   addCommands() {
    //     return {
    //       toggleInsertedText: () => toggleWrap(this.name),
    //     };
    //   },
});

const DeletedText = Node.create({
    name: 'deleted_text',
    priority: 1000,
    group: 'inline',
    inline: true,
    content: 'inline*',

    parseHTML() {
        return [
            {
                tag: 'del',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['del', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    // TODO: toggleWrap 함수를 사용할수가 없음. 방법 찾아서 적용해야함.
    //   addCommands() {
    //     return {
    //       toggleDeletedText: () => toggleWrap(this.name),
    //     };
    //   },
});

export { InsertedText, DeletedText };
