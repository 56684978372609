import { Node, mergeAttributes } from '@tiptap/core';

export const WordBody = Node.create({
    name: 'body',
    group: 'body',
    selectable: false,
    allowGapCursor: false,
    content: '(paragraph|block|pageBreak?)+',
    parseHTML() {
        return [
            {
                tag: 'div.word-body',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ class: 'word-body relative z-10' }, HTMLAttributes), 0];
    },
});
