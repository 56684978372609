import { Extension } from '@tiptap/core';
import { isEmpty } from 'lodash-es';

export const WordExtras = Extension.create({
    addGlobalAttributes() {
        return [
            {
                types: [/* 'paragraph', 'table', 'tableRow', 'tableHeader', 'listItem', */ 'section', 'tableCell', 'orderedList', 'bulletList'],
                attributes: {
                    extras: {
                        default: {},
                        renderHTML: attributes => {
                            if (isEmpty(attributes.extras)) {
                                return {};
                            }
                            return {
                                'data-extras': JSON.stringify(attributes.extras),
                            };
                        },
                        parseHTML: element => (element.dataset.extras ? JSON.parse(element.dataset.extras) : {}),
                    },
                },
            },
        ];
    },
});
