import { Node, mergeAttributes } from '@tiptap/core';

export const WordToc = Node.create({
    name: 'wordToc',
    group: 'block',
    content: 'wordHyperlink*',
    atom: true,
    parseHTML() {
        return [
            {
                tag: 'div.word-toc',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ class: 'word-toc' }, HTMLAttributes), 0];
    },
});

export const WordHyperlinkNode = Node.create({
    name: 'wordHyperlink',
    group: 'block',
    content: 'paragraph',
    atom: true,
    draggable: true,

    addAttributes() {
        return {
            id: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.word-hyperlink',
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return ['div', mergeAttributes({ 'data-id': node.attrs.id, class: 'word-hyperlink' }, HTMLAttributes), 0];
    },

    addNodeView() {
        return ({ node }) => {
            const container = document.createElement('div');
            container.setAttribute('data-id', node.attrs.id);
            container.setAttribute('class', 'word-hyperlink cursor-pointer hover:bg-blue-50');
            container.addEventListener('click', e => {
                e.preventDefault();
                document.querySelector(`.word-bookmark[data-id="${node.attrs.id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
            const content = document.createElement('div');
            container.append(content);
            return {
                dom: container,
                contentDOM: content,
            };
        };
    },
});

export const WordBookmarkNode = Node.create({
    name: 'wordBookmark',
    group: 'block',
    content: 'paragraph*',

    addAttributes() {
        return {
            id: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.word-bookmark',
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return ['div', mergeAttributes({ 'data-id': node.attrs.id, class: 'word-bookmark hover:bg-blue-50' }, HTMLAttributes), 0];
    },
});
