import { mergeAttributes, Node } from '@tiptap/core';
import { isEqual } from 'lodash-es';

export interface ParagraphOptions {
    HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        // eslint-disable-next-line
        // @ts-ignore
        paragraph: {
            /**
             * 선택한 노드를 paragraph로 변경합니다.
             */
            setParagraph: () => ReturnType;
            /**
             * paragraph 노드의 속성을 변경합니다.
             */
            setParagraphBreakFont: (paragraphBreakFont: {
                fontFamily: string;
                fontSize: string;
                backgroundColor: string;
                color: string;
                bold: boolean;
                italic: boolean;
                underline: boolean;
                strike: boolean;
                subscript: boolean;
                superscript: boolean;
            }) => ReturnType;
            /**
             * paragraph 노드의 속성을 적용합니다.
             */
            applyParagraphBreakFont: () => ReturnType;
        };
    }
}

export const WordParagraph = Node.create<ParagraphOptions>({
    name: 'paragraph',

    priority: 1000,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },
    addAttributes() {
        return {
            paragraphBreakFont: {
                default: null,
                parseHTML: element => JSON.parse(element.dataset.paragraphBreakFont || 'null') || null,
                renderHTML: attributes => {
                    if (attributes.paragraphBreakFont === null) {
                        return {};
                    }
                    return {
                        'data-paragraph-break-font': JSON.stringify(attributes.paragraphBreakFont),
                    };
                },
            },
        };
    },
    // 처음 에디터가 생성되었을때 paragraphBreakFont가 세팅될 수 있도록 설정
    onFocus() {
        const { editor } = this;
        const storedMarks = editor.state.storedMarks;
        if (!storedMarks) {
            const { empty, $anchor, $from, $to } = editor.state.selection;
            // 셀렉션으로 선택한 영역이 없고 새로운 문단에 커서가 위치했을때 기본 textStyle mark 스타일 적용
            if (editor.isEditable && empty && $anchor.parent.type.name === this.name && $anchor.parentOffset === 0 && !$anchor.nodeAfter && !editor.isActive('textStyle')) {
                if ($anchor.parent.attrs.paragraphBreakFont) {
                    editor.commands.applyParagraphBreakFont();
                } else {
                    editor.commands.setFontSize('12pt');
                }
            }
        }
    },
    onUpdate() {
        const { editor } = this;
        const storedMarks = editor.state.storedMarks;
        if (!storedMarks) return;
        const { empty, $anchor, $from, $to } = editor.state.selection;
        if (empty && $anchor.parent.type.name === this.name && $anchor.parentOffset === 0 && !$anchor.nodeAfter && editor.isActive('textStyle')) {
            const textStyle = storedMarks?.find(val => val.type.name === 'textStyle')?.attrs;
            const bold = storedMarks?.find(val => val.type.name === 'bold');
            const italic = storedMarks?.find(val => val.type.name === 'italic');
            const underline = storedMarks?.find(val => val.type.name === 'underline');
            const strike = storedMarks?.find(val => val.type.name === 'strike');
            const subscript = storedMarks?.find(val => val.type.name === 'subscript');
            const superscript = storedMarks?.find(val => val.type.name === 'superscript');
            const paragraphBreakFont = {
                ...textStyle,
                bold: !!bold,
                italic: !!italic,
                underline: !!underline,
                strike: !!strike,
                subscript: !!subscript,
                superscript: !!superscript,
            };
            if (!isEqual($anchor.parent.attrs.paragraphBreakFont, paragraphBreakFont)) {
                editor.commands.setParagraphBreakFont(paragraphBreakFont);
                editor.commands.applyParagraphBreakFont();
            }
        }
    },
    onSelectionUpdate() {
        const { editor } = this;
        const { empty, $anchor, $from, $to } = editor.state.selection;
        // 셀렉션으로 선택한 영역이 없고 새로운 문단에 커서가 위치했을때 기본 textStyle mark 스타일 적용
        if (editor.isEditable && empty && $anchor.parent.type.name === this.name && $anchor.parentOffset === 0 && !$anchor.nodeAfter && !editor.isActive('textStyle')) {
            if ($anchor.parent.attrs.paragraphBreakFont) {
                editor.commands.applyParagraphBreakFont();
            } else {
                editor.commands.setFontSize('12pt');
            }
        }
    },

    group: 'block',

    content: 'inline*',

    parseHTML() {
        return [{ tag: 'p' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            setParagraph:
                () =>
                ({ commands }) => {
                    return commands.setNode(this.name);
                },
            setParagraphBreakFont:
                paragraphBreakFont =>
                ({ commands }) => {
                    return commands.updateAttributes(this.name, { paragraphBreakFont });
                },
            applyParagraphBreakFont:
                () =>
                ({ editor, chain, ...rest }) => {
                    const { empty, $anchor, $from, $to } = editor.state.selection;
                    const paragraphNode = $anchor.node($anchor.depth);
                    if (paragraphNode.type.name === 'paragraph') {
                        const paragraphBreakFont = paragraphNode.attrs.paragraphBreakFont;
                        return chain()
                            .focus()
                            .setFontFamily(paragraphBreakFont?.fontFamily || 'Pretendard')
                            .setFontSize(paragraphBreakFont?.fontSize || '12pt')
                            .setBackgroundColor(paragraphBreakFont?.backgroundColor || null)
                            .setColor(paragraphBreakFont?.color || null)
                            [paragraphBreakFont?.bold ? 'setBold' : 'unsetBold']()
                            [paragraphBreakFont?.italic ? 'setItalic' : 'unsetItalic']()
                            [paragraphBreakFont?.underline ? 'setUnderline' : 'unsetUnderline']()
                            [paragraphBreakFont?.strike ? 'setStrike' : 'unsetStrike']()
                            [paragraphBreakFont?.subscript ? 'setSubscript' : 'unsetSubscript']()
                            [paragraphBreakFont?.superscript ? 'setSuperscript' : 'unsetSuperscript']()
                            .run();
                    }
                    return true;
                },
        };
    },
});
