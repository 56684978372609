import { tailwindConfig } from '$lib/utils';
import type { BhsnInlineReferenceItem, BhsnReferenceOptions } from './BhsnReferenceExtension';
import { Node, mergeAttributes } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';

export const BhsnReferenceUnlinkExtension = Node.create<BhsnReferenceOptions>({
    name: 'reference',
    group: 'inline',
    content: 'inline*',
    inline: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-reference', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            toggleReference:
                () =>
                ({ tr, state, dispatch }) => {
                    const { selection, schema, doc } = state;
                    const { from, to } = selection;
                    const referenceNodeType = schema.nodes.reference;

                    const referenceNodePositions: number[] = [];
                    state.doc.nodesBetween(from, to, (node, pos) => {
                        if (node.type === referenceNodeType) {
                            referenceNodePositions.push(pos);
                        }
                    });

                    if (referenceNodePositions.length) {
                        // 'reference' 노드를 텍스트로 변환
                        referenceNodePositions.forEach(pos => {
                            const node = tr.doc.nodeAt(pos);
                            const textContent = node!.attrs['data-id'];
                            tr.replaceWith(pos, pos + node!.nodeSize, schema.text(textContent));
                        });
                    } else {
                        // 선택된 텍스트를 가져와 'data-id' 속성에 설정한 뒤 'reference' 노드로 변환
                        const textContent = doc.textBetween(from, to);
                        const content = schema.text(textContent);
                        const referenceNode = referenceNodeType.create({ 'data-id': textContent, 'data-name': textContent }, content);
                        tr.replaceWith(from, to, referenceNode);
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // 특정 id를 포함하는 값을 받았을때 data-id이 같은 값을 child content로 추가합니다.
            updateReferenceContent:
                attributes =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const ids = attributes.map(attr => attr.id);
                    const nodeInfos: any[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType && ids.includes(node.attrs['data-id'])) {
                            nodeInfos.push({ node, pos });
                        }
                    });

                    // Process nodes in reverse order to avoid the position out of range issue
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { id, value } = attributes.find(attr => attr.id === node.attrs['data-id']) || {};

                        if (id) {
                            let fragment;
                            if (value) {
                                const parts = value.split('\n');
                                const nodes: any[] = [];

                                parts.forEach((part, index) => {
                                    if (part) nodes.push(schema.text(part));
                                    if (index < parts.length - 1) {
                                        nodes.push(schema.nodes.hardBreak.create());
                                    }
                                });

                                fragment = Fragment.from(nodes);
                            } else {
                                fragment = Fragment.empty;
                            }

                            const updatedNode = node.copy(fragment);
                            tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                        }
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputReferenceContent:
                (id, scrollInto = false, scrollIntoOption = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    let referenceNodePosition: number | null = null;

                    // 선택한 reference 노드에만 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            const { attrs } = node;
                            if (id && id === node.attrs['data-id']) {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': true });
                                if (referenceNodePosition === null) referenceNodePosition = pos;
                            } else {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': undefined });
                            }
                        }
                    });

                    if (scrollInto && referenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(referenceNodePosition);
                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(scrollIntoOption);
                            });
                        }
                    }
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 focus했을때 해당 reference로 스크롤합니다.
            scrollIntoFocusedInputReferenceContent:
                (id, option = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state }) => {
                    if (!id) return false;
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    let referenceNodePosition: number | null = null;

                    doc.descendants((node, pos) => {
                        if (referenceNodePosition !== null && node.type === referenceNodeType && id === node.attrs['data-id']) {
                            referenceNodePosition = pos;
                            return false;
                        }
                    });

                    if (referenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(referenceNodePosition);

                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(option);
                            });
                        }
                    }
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getBhsnInlineReferenceItems:
                (resolveCallback = () => {}) =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const references: BhsnInlineReferenceItem[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            references.push({
                                id: node.attrs['data-id'],
                                name: node.attrs['data-name'],
                                content: node.content,
                                size: node.content.size,
                                text: node.textContent,
                            });
                        }
                    });
                    resolveCallback(references);
                    return true;
                },
        };
    },
});
