import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        uniqueId: {
            // 문서 전체를 순회하며 guid가 없는 노드에 guid를 생성하여 삽입 / guid가 중복되는 경우 새로운 guid를 생성하여 삽입
            // 문서 사이즈가 길어서 처리에 시간이 걸리는 경우 selection을 기준으로 앞뒤면 계산한다던가 하는 방법을 사용할 수도 있지만 global 한 unique 값인지 확인이 필요함
            // 결국 유저의 동작(오려서 붙여넣기, 드래그) 등을 체크해서 기존에서 붙여넣어진 guid를 넣는 방식이 되어야하는데 이는 더 복잡한 로직이 필요함
            fillUniqueId: () => ReturnType;
        };
    }
}

export const WordUniqueId = Extension.create({
    priority: 1,
    defaultOptions: {
        types: ['paragraph', 'table', 'listItem', 'orderedList', 'bulletList'],
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    guid: {
                        renderHTML: attributes => {
                            if (!attributes.guid) {
                                return {};
                            }
                            return {
                                'data-guid': attributes.guid,
                            };
                        },
                        parseHTML: element => element.dataset.guid,
                    },
                },
            },
        ];
    },
    addCommands() {
        return {
            fillUniqueId:
                () =>
                ({ tr, state, dispatch }) => {
                    const allGuids = {};
                    let changed = false;
                    tr.doc.descendants((node, pos) => {
                        if (this.options.types.includes(node.type.name)) {
                            if (!node.attrs.guid || allGuids[node.attrs.guid]) {
                                const attrs = node.attrs;
                                const guid = crypto.randomUUID();
                                tr.setNodeMarkup(pos, undefined, { ...attrs, guid });
                                allGuids[guid] = guid;
                                changed = true;
                            } else if (!allGuids[node.attrs.guid]) {
                                allGuids[node.attrs.guid] = true;
                            }
                        }
                    });
                    if (changed && dispatch) {
                        tr.setMeta('addToHistory', false);
                        tr.setMeta('preventUpdate', true);
                        dispatch(tr);
                    }
                    return true;
                },
        };
    },
    onTransaction({ transaction }) {
        if (transaction.docChanged && !transaction.getMeta('preventUpdate')) {
            // Transaction 단계에서 추가 dispatch 를 할 경우 redline 작업과 얽히는 문제가 있을 수 있어서 추가 commands 호출로 처리
            this.editor.commands.fillUniqueId();
        }
    },
});
