<script context="module" lang="ts">
    import type { Editor } from 'svelte-tiptap';
    import { setPrototypeOfEditorUtils } from './editorUtils';
    export type EditorType = 'StandardForm' | 'Normal';
    setPrototypeOfEditorUtils();
</script>

<script lang="ts">
    import { onMount, createEventDispatcher } from 'svelte';
    import type { Readable } from 'svelte/store';
    import { createEditor, EditorContent } from 'svelte-tiptap';
    import WordStarterPack from './extension-word/WordStarterPack';
    import { BhsnNormalExtensionPack, BhsnStandardFormExtensionPack } from './extension-reference/BhsnExtensionPack';
    import { alert } from '$lib/utils';

    let svelteTiptapEditor: Readable<Editor>;
    export let editor: Editor | null = null;
    export let content: string | object;
    export let editable = true;
    export let defaultClass = 'ProseMirror tiptap word-editor relative focus:outline-none focus:border-transparent';
    export let type: EditorType = 'Normal';
    const dispatch = createEventDispatcher();

    $: editable, setChangedEditable();
    $: type, setChangedType();

    let extensionsConfig = getExtensionConfig();

    onMount(() => {
        initializeEditor();
    });

    async function initializeEditor() {
        extensionsConfig = getExtensionConfig();
        const prevEditor = $svelteTiptapEditor;
        editor = null;
        svelteTiptapEditor = createEditor({
            editable,
            extensions: extensionsConfig,
            autofocus: 'start',
            content,
            onCreate: () => {
                editor = $svelteTiptapEditor;
                editor.initializeUtils();
                dispatch('create', {
                    editor,
                    getContentJson,
                    getContentHtml,
                });
            },
            onTransaction: () => {
                if (!editable) return;
                // force re-render so `editor.isActive` works as expected
                editor = $svelteTiptapEditor;
                dispatch('transaction', {
                    editor,
                    getContentJson,
                    getContentHtml,
                });
            },
            onUpdate({ editor }) {
                dispatch('update', {
                    editor,
                    getContentJson,
                    getContentHtml,
                });
            },
            onSelectionUpdate({ editor }) {
                if (!editable) return;
                dispatch('selectionUpdate', {
                    editor,
                    getContentJson,
                    getContentHtml,
                });
            },
            // editor 자체 랩퍼에 대한 설정
            editorProps: {
                attributes: {
                    class: defaultClass,
                    style: 'tab-size: 8;',
                    spellcheck: 'false',
                },
                transformPasted(slice, view) {
                    // 붙여넣기가 완료되었을때 항상 호출됨
                    return slice;
                },
            },
            parseOptions: {
                // preserveWhitespace: 'full', // HTML의 공백을 그대로 유지한다. 줄바꿈을 포함하기 때문에 sample.html의 경우에는 불필요한 공백이 많아진다.
            },
        });

        // 혹시모를 destroy 처리
        prevEditor?.destroy();
    }

    function setChangedEditable() {
        $svelteTiptapEditor?.setEditable(editable);
    }

    function setChangedType() {
        if (!$svelteTiptapEditor) return;
        initializeEditor();
    }

    function getExtensionConfig() {
        if (type === 'StandardForm') {
            return [WordStarterPack, BhsnStandardFormExtensionPack];
        } else if (type === 'Normal') {
            return [WordStarterPack, BhsnNormalExtensionPack];
        }
        return [];
    }

    export function getEditor() {
        return $svelteTiptapEditor;
    }

    export function getContentHtml() {
        try {
            return $svelteTiptapEditor.getHTML();
        } catch (err) {
            console.error('getContentHtml Invalid document format.', err);
            alert('Invalid document format.');
            return '';
        }
    }

    export function getContentJson() {
        return $svelteTiptapEditor.getJSON();
    }

    export function setContent(content) {
        $svelteTiptapEditor?.chain().setMeta('replacedContent', true).setContent(content, true, { preserveWhitespace: 'full' }).run();
    }
</script>

<EditorContent editor={$svelteTiptapEditor} />

<!-- 
@component
## Features
    - editable
    - non-editable

## Props
    @prop editor: Editor | null = null;
    @prop content: string | object;
    @prop editable = true;
    @prop defaultClass = 'ProseMirror tiptap relative focus:outline-none focus:border-transparent';

## Events
    @event create: { editor: Editor, getContentJson: function, getContentHtml: function};
    @event update: { editor: Editor, getContentJson: function, getContentHtml: function };
    @event selectionUpdate: { editor: Editor, getContentJson: function, getContentHtml: function };

## Methods
    @method getEditor(): Editor;
    @method getContentHtml(): string;
    @method getContentJson(): object;
    @method setContent(content: string | object): void;

## Example
    ```
        <script>
            import { WordEditor } from '$components/tiptap';

            let editor;
            let content = '<div></div>';
            function handleCreate() {}
        </script>
        <WordEditor {content} {editable} {defaultClass} on:create={handleCreate} on:update={handleUpdateResultJSON} />
        <WordEditor content={content} bind:editor editable={true} on:update={e => (resultJSON = e.detail.getContentJson())} />
    ```
-->

<style lang="postcss">
    :global(.ProseMirror.word-editor table .selectedCell:after) {
        z-index: 2;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
    }

    :global(.ProseMirror.word-editor table .column-resize-handle) {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
    }

    :global(.ProseMirror.word-editor .resize-cursor) {
        cursor: ew-resize;
        cursor: col-resize;
    }

    :global(.word-image.ProseMirror-selectednode) {
        @apply ring;
    }

    :global(span[data-insert-revision] > .word-image) {
        filter: drop-shadow(0 0 1px currentColor);
    }
    :global(span[data-move-to-revision] > .word-image) {
        filter: drop-shadow(0 0 1px currentColor);
    }
    :global(span[data-delete-revision] > .word-image) {
        @apply relative;
        filter: drop-shadow(0 0 1px currentColor);
    }
    :global(span[data-move-from-revision] > .word-image) {
        @apply relative;
        filter: drop-shadow(0 0 1px currentColor);
    }
    :global(span[data-delete-revision] > .word-image::after) {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        border-top: 1px solid currentColor;
        transform: translateY(-50%);
    }
</style>
