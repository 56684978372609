import { Node, mergeAttributes } from '@tiptap/core';

type PageIndexType = 'current-page' | 'total-page' | 'section-page';

export const WordPageNumber = Node.create({
    name: 'pageNumber',
    group: 'inline',
    content: 'text*',
    atom: true,
    inline: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            pageIndexType: {
                default: 'current-page' as PageIndexType,
            },
        };
    },

    parseHTML() {
        return [{ tag: 'span.word-page-number' }];
    },
    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes({ class: 'word-page-number', contenteditable: false }, this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
