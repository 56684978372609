import { Extension } from '@tiptap/core';

// Tab 키를 눌렀을 때 에디터 밖으로 탭키가 벗어나는 것을 방지하기 위한 Extension
const TabKeyPrevent = Extension.create({
    name: 'customTab',

    addKeyboardShortcuts() {
        return {
            Tab: () => {
                // TODO: redline에서 tab에 decoration이 제대로 먹히지 않거나 revison태그로 합쳐지면서 문제가 발생해서 주석처리함
                // const transaction = this.editor.view.state.tr.insertText('\t', this.editor.view.state.selection.from, this.editor.view.state.selection.to);
                // this.editor.view.dispatch(transaction);
                return true;
            },
            'Shift-Tab': () => {
                return true;
            },
        };
    },
});

export default TabKeyPrevent;
