import { Node, mergeAttributes } from '@tiptap/core';

export const WordFooter = Node.create({
    name: 'footer',
    group: 'footer',
    content: 'block+',
    parseHTML() {
        return [
            {
                tag: 'div.word-footer',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ class: 'word-footer' }, HTMLAttributes), 0];
    },
});
