import { type CommandProps, Extension } from '@tiptap/core';
import type { Node } from '@tiptap/pm/model';
import { convertToPt } from '../extension-word/utils';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        wordIndent: {
            indent: (indentOnlyAtHead: boolean) => ReturnType;
            outdent: (outdentOnlyAtHead: boolean) => ReturnType;
        };
    }
}

type IndentOptions = {
    types: Array<string>;
    indentSize: string;
    minIndentLevel: number;
    maxIndentLevel: number;
};

const clamp = (val: number, min: number, max: number): number => {
    if (val < min) {
        return min;
    }
    if (val > max) {
        return max;
    }
    return val;
};

export const WordIndent = Extension.create<IndentOptions, never>({
    name: 'wordIndent',

    addOptions() {
        return {
            types: ['paragraph', 'orderedList', 'bulletList'],
            indentSize: '36pt',
            minIndentLevel: 0,
            maxIndentLevel: 10,
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    indent: {
                        default: '0pt',
                        renderHTML: attributes =>
                            attributes.indent
                                ? {
                                      style: `margin-left: ${attributes.indent}`,
                                  }
                                : {},
                        parseHTML: element => (element.style.marginLeft ? `${convertToPt(element.style.marginLeft)}pt` : '0pt'),
                    },
                    firstLineIndent: {
                        default: '0pt',
                        renderHTML: attributes =>
                            attributes.firstLineIndent
                                ? {
                                      style: `text-indent: ${attributes.firstLineIndent}`,
                                  }
                                : {},
                        parseHTML: element => (element.style.textIndent ? `${convertToPt(element.style.textIndent)}pt` : '0pt'),
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            // Tab이나 Shift+Tab 키를 눌렀을 때, 커서의 위치가 맨 앞일때가 아니면 indent를 실행하지 않고, 툴바를 통해 실행할 때는 항상 실행하기 때문에 indentOnlyAtHead 옵션을 추가
            indent:
                (indentOnlyAtHead = false) =>
                ({ tr, state, dispatch, editor }: CommandProps) => {
                    if (indentOnlyAtHead && editor.state.selection.ranges[0]?.$from.parentOffset !== 0) {
                        return false;
                    }
                    const { selection } = state;
                    const { from, to } = selection;
                    tr = tr.setSelection(selection);
                    const nodes: [Node, number][] = [];
                    state.doc.nodesBetween(from, to, (node, pos) => {
                        if (this.options.types.includes(node.type.name)) {
                            nodes.push([node, pos]);
                            return false;
                        }
                    });

                    const maxIndentSize = this.options.maxIndentLevel * convertToPt(this.options.indentSize);
                    nodes.forEach(([node, pos]) => {
                        const prevIndentSizeInPt: number = node.attrs.indent ? convertToPt(node.attrs.indent) : 0;
                        const indentSizeInPt = convertToPt(this.options.indentSize);
                        let nextIndentSizeInPt = Math.floor(prevIndentSizeInPt / indentSizeInPt) * indentSizeInPt + indentSizeInPt;
                        nextIndentSizeInPt = clamp(nextIndentSizeInPt, 0, maxIndentSize);
                        const nodeAttrs = {
                            ...node.attrs,
                            indent: nextIndentSizeInPt ? `${nextIndentSizeInPt}pt` : '0pt',
                        };
                        tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
                    });
                    if (tr.docChanged && dispatch) {
                        dispatch(tr);
                        return true;
                    }
                    return false;
                },
            // Backspace 키를 눌렀을 때, 커서의 위치가 맨 앞일때가 아니면 outdent를 실행하지 않고, 툴바를 통해 실행할 때는 항상 실행하기 때문에 outdentOnlyAtHead 옵션을 추가
            outdent:
                (outdentOnlyAtHead = false) =>
                ({ tr, state, dispatch, editor }: CommandProps) => {
                    if (outdentOnlyAtHead && editor.state.selection.$head.parentOffset > 0) {
                        return false;
                    }
                    if ((!outdentOnlyAtHead || editor.state.selection.$head.parentOffset > 0) && editor.can().liftListItem('listItem')) {
                        return editor.chain().focus().liftListItem('listItem').run();
                    }

                    const { selection } = state;
                    const { from, to } = selection;

                    tr = tr.setSelection(selection);
                    const nodes: [Node, number][] = [];
                    state.doc.nodesBetween(from, to, (node, pos) => {
                        if (this.options.types.includes(node.type.name)) {
                            nodes.push([node, pos]);
                            return false;
                        }
                    });

                    const maxIndentSize = this.options.maxIndentLevel * convertToPt(this.options.indentSize);
                    nodes.forEach(([node, pos]) => {
                        const prevIndentSizeInPt: number = node.attrs.indent ? convertToPt(node.attrs.indent) : 0;
                        const indentSizeInPt = convertToPt(this.options.indentSize);
                        let nextIndentSizeInPt = Math.floor((prevIndentSizeInPt - 1) / indentSizeInPt) * indentSizeInPt;
                        nextIndentSizeInPt = clamp(nextIndentSizeInPt, 0, maxIndentSize);
                        const nodeAttrs = {
                            ...node.attrs,
                            indent: nextIndentSizeInPt ? `${nextIndentSizeInPt}pt` : '0pt',
                        };
                        if (node.attrs.indent === nodeAttrs.indent) return;
                        tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
                    });
                    if (tr.docChanged && dispatch) {
                        dispatch(tr);
                        return true;
                    }
                    return false;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            Tab: () => this.editor.chain().focus().indent(true).run(),
            'Shift-Tab': () => this.editor.chain().focus().outdent().run(),
            // Backspace: () => this.editor.chain().focus().outdent(true).run(), // TrackChange 에서의 동작과 충돌이 있어서 주석처리
            'Mod-]': () => this.editor.chain().focus().indent().run(),
            'Mod-[': () => this.editor.chain().focus().outdent().run(),
        };
    },
});
