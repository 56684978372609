import { Node, mergeAttributes } from '@tiptap/core';
import { SvelteNodeViewRenderer } from 'svelte-tiptap';
import BhsnBlockReferenceComponent from './BhsnBlockReferenceComponent.svelte';
import { Fragment } from '@tiptap/pm/model';

export type BhsnBlockReferenceItem = {
    id: string;
    name: string;
    content: Fragment;
    size: number;
    text: string;
};

export interface BhsnReferenceOptions {
    HTMLAttributes: Record<string, any>;
}
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        blockReference: {
            // toggleBlockReference: () => ReturnType;
            updateBlockReferenceContent: (
                attributes: {
                    [x: string]: any;
                }[],
            ) => ReturnType;
            focusedInputBlockReferenceContent: (id: string, scrollInto?: boolean, scrollIntoOption?: ScrollIntoViewOptions) => ReturnType;
            scrollIntoFocusedInputBlockReferenceContent: (id: string, option?: ScrollIntoViewOptions) => ReturnType;
            blurInputBlockReferenceContent: () => ReturnType;
            getBhsnBlockReferenceItems: (resolveCallback: (data: BhsnBlockReferenceItem[]) => void) => ReturnType;
        };
    }
}

export const BhsnBlockReferenceExtension = Node.create<BhsnReferenceOptions>({
    name: 'blockReference',
    group: 'block',
    content: 'block*',
    atom: true,
    draggable: true, // Optional: to make the node draggable
    inline: false,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
            'focused-input': {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-block-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-block-reference', mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        return {
            // blockReference 노드를 토글할 수는 있지만 new contract 뷰어에서의 사용성이 maker에서는 좋지않아서 주석처리
            // maker를 위한 blockReference 를 별도로 구현해야할것으로 보임
            // atom 속성이나 draggable, contenteditable 과 같은 여러 속성 때문에 사용성이 나빠지는 부분도 존재함
            // 아래 주석처리된 구현에서는 text를 컨텐츠로 담고 있지만 실제로는 여러가지 block json을 받는식이 되어야하기도 할듯함.
            /* toggleBlockReference:
                () =>
                ({ tr, state, dispatch }) => {
                    const { selection, schema, doc } = state;
                    const { from, to } = selection;
                    const blockReferenceNodeType = schema.nodes.blockReference;

                    const blockReferenceNodePositions: number[] = [];
                    state.doc.nodesBetween(from, to, (node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            blockReferenceNodePositions.push(pos);
                        }
                    });

                    if (blockReferenceNodePositions.length) {
                        // 'blockReference' 노드를 텍스트로 변환
                        blockReferenceNodePositions.forEach(pos => {
                            const node = tr.doc.nodeAt(pos);
                            const textContent = node!.attrs['data-id'];
                            tr.replaceWith(pos, pos + node!.nodeSize, schema.text(textContent));
                        });
                    } else {
                        // 선택된 텍스트를 가져와 'data-id' 속성에 설정한 뒤 'blockReference' 노드로 변환
                        const textContent = doc.textBetween(from, to);
                        const blockReferenceNode = blockReferenceNodeType.create({ 'data-id': textContent, 'data-name': textContent });
                        tr.replaceWith(from, to, blockReferenceNode);
                    }

                    if (dispatch) dispatch(tr);
                    return true;
                }, */
            // 특정 id를 포함하는 값을 받았을때 data-id이 같은 값을 child content로 추가합니다.
            updateBlockReferenceContent:
                attributes =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema, apply } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    const ids = attributes.map(attr => attr.id);
                    const nodeInfos: any[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType && ids.includes(node.attrs['data-id'])) {
                            nodeInfos.push({ node, pos });
                        }
                    });

                    // Process nodes in reverse order to avoid the position out of range issue
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { id, value } = attributes.find(attr => attr.id === node.attrs['data-id']) || {};

                        if (id) {
                            let fragment;
                            if (value) {
                                if (typeof value === 'object') {
                                    fragment = Fragment.fromJSON(schema, value);
                                } else {
                                    fragment = Fragment.fromJSON(schema, [
                                        {
                                            type: 'paragraph',
                                            content: [{ type: 'text', text: value }],
                                        },
                                    ]);
                                }
                            } else {
                                fragment = Fragment.empty;
                            }

                            const updatedNode = node.copy(fragment);
                            tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                        }
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 block reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputBlockReferenceContent:
                (id, scrollInto = false, scrollIntoOption = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    let blockReferenceNodePosition: number | null = null;

                    // 선택한 reference 노드에만 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            if (id && id === node.attrs['data-id']) {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': true });
                                if (blockReferenceNodePosition === null) blockReferenceNodePosition = pos;
                            } else {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': undefined });
                            }
                        }
                    });

                    if (scrollInto && blockReferenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(blockReferenceNodePosition);
                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(scrollIntoOption);
                            });
                        }
                    }
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 block reference를 focus했을때 해당 reference로 스크롤합니다.
            scrollIntoFocusedInputBlockReferenceContent:
                (id, option = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state }) => {
                    if (!id) return false;
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    let blockReferenceNodePosition: number | null = null;

                    doc.descendants((node, pos) => {
                        if (!blockReferenceNodePosition && node.type === blockReferenceNodeType && id === node.attrs['data-id']) {
                            blockReferenceNodePosition = pos;
                            return false;
                        }
                    });

                    if (blockReferenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(blockReferenceNodePosition);

                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(option);
                            });
                        }
                    }
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputBlockReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getBhsnBlockReferenceItems:
                (resolveCallback = () => {}) =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.blockReference;
                    const references: BhsnBlockReferenceItem[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            references.push({
                                id: node.attrs['data-id'],
                                name: node.attrs['data-name'],
                                content: node.content,
                                size: node.content.size,
                                text: node.textContent,
                            });
                        }
                    });

                    resolveCallback(references);
                    return true;
                },
        };
    },
    addNodeView() {
        return SvelteNodeViewRenderer(BhsnBlockReferenceComponent);
    },
});
