import { Mark, mergeAttributes, type Range } from '@tiptap/core';
import { DOMSerializer, type Mark as PMMark } from '@tiptap/pm/model';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { intersection, isEqual } from 'lodash-es';
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        comment: {
            setComment: (commentId: string) => ReturnType;
            unsetComment: (commentId: string) => ReturnType;
            unsetCommentWithRange: (commentId: string) => ReturnType;
            removeAllCommentsWithId: (commentId: string) => ReturnType;
            removeAllComments: () => ReturnType;
            addClassToComment: (commentId: string, className: string) => ReturnType;
            removeClassFromComment: (commentId: string, className: string) => ReturnType;
            moveFocusToComment: (commentId: string, scrollInto?: boolean, scrollIntoOption?: ScrollIntoViewOptions) => ReturnType;
            setCommentClass: (className: string) => ReturnType;
            setCommentClasses: (commentClasses: CommentClasses) => ReturnType;
            setCommentHoverClass: (className: string) => ReturnType;
            setCommentLineClass: (className: string) => ReturnType;
            applyFocusedDecorationByComment: (commentId: string) => ReturnType;
            removeFocusedDecoration: () => ReturnType;
            applyDecorationClassToComment: (commentId: string, commentClasses: CommentClasses) => ReturnType;
            removeDecorationClassFromComment: (commentId: string, commentClasses: CommentClasses) => ReturnType;
        };
    }
}

export interface MarkWithRange {
    mark: PMMark;
    range: Range;
}

export interface CommentOptions {
    HTMLAttributes: Record<string, any>;
    onCommentActivated: (commentIds: string[] | null) => void;
}

export interface CommentClasses {
    normal: string;
    hover: string;
    line: string;
}

export interface CommentStorage {
    activeCommentIds: string[] | null;
    commentClass: CommentClasses;
    customClasses: Record<string, CommentClasses>;
}

const COMMENT_ATTR_NAME = 'data-comment-ids';
const COMMENT_DEFAULT_CLASS = 'bg-blue-100';
const COMMENT_DEFAULT_HOVER_CLASS = 'bg-blue-200';
const COMMENT_DEFAULT_LINE_CLASS = 'border-blue-600';

/**
 * 에디터 내부의 모든 'comment' 마크 속성을 찾아서 리턴합니다.
 * @param state
 * @returns {Array} 'comment' 마크의 속성 배열
 * @example
 * getAllComments(state);
 * [
 *   {
 *     attrs: { commentIds: ['comment-1'] },
 *     from: 0,
 *     to: 10,
 *   },
 *   {
 *     attrs: { commentIds: ['comment-2'] },
 *     from: 10,
 *     to: 20,
 *   },
 * ]
 */
export function getAllComments(state) {
    const { doc, schema } = state;
    const { comment } = schema.marks;
    const comments: any[] = [];

    // 문서의 모든 노드를 순회
    doc.descendants((node, pos) => {
        node.marks.forEach(mark => {
            if (mark.type === comment) {
                // 'comment' 마크의 속성 수집
                comments.push({
                    attrs: mark.attrs,
                    from: pos,
                    to: pos + node.nodeSize,
                });
            }
        });
    });

    // 동일한 commentIds 속성을 가진 마크를 병합
    const mergedComments: any[] = [];
    comments.forEach(comment => {
        const mergedComment = mergedComments.find(mergedComment => isEqual(mergedComment.attrs.commentIds, comment.attrs.commentIds));
        if (mergedComment) {
            mergedComment.from = Math.min(mergedComment.from, comment.from);
            mergedComment.to = Math.max(mergedComment.to, comment.to);
        } else {
            mergedComments.push(comment);
        }
    });

    // 수집된 'comment' 마크의 속성 반환
    return mergedComments;
}

// 에디터 내부의 모든 comment 마크 속성을 가진 commentId 의 리스트를 리턴합니다.
export function getAllCommentId(state) {
    const comments = getAllComments(state);
    return [...new Set(comments.map(comment => comment.attrs?.commentIds).flat())];
}

export function getAllCommentsWithText(state) {
    const comments: any[] = [];
    const { doc, schema } = state;
    const { comment } = schema.marks;

    let isFirstBlockInListItem = false; // 리스트 항목의 첫 번째 블록 노드인지 추적

    doc.descendants((node, pos) => {
        if (node.type.name === 'listItem') {
            isFirstBlockInListItem = true; // 새로운 listItem 시작
        } else if (node.isBlock) {
            if (!isFirstBlockInListItem) {
                // listItem의 첫 번째 블록이 아니면 줄바꿈 추가
                Object.values(comments).forEach(entry => {
                    entry.innerText += '\n';
                });
            }
            isFirstBlockInListItem = false; // 다음 노드는 첫 번째 블록이 아님
        }

        if (node.type.name === 'hardBreak') {
            // hardBreak 노드에 도달하면 줄바꿈 추가
            Object.values(comments).forEach(entry => {
                entry.innerText += '\n';
            });
        }

        node.marks
            .filter(mark => mark.type === comment)
            .forEach(mark => {
                mark.attrs.commentIds.forEach(commentId => {
                    let existingComment = comments.find(c => c.commentId === commentId);

                    if (!existingComment) {
                        existingComment = { commentId, innerText: '' };
                        comments.push(existingComment);
                    }

                    if (node.isText) {
                        existingComment.innerText += node.textContent;
                    } else if (node.isInline && node.type.name === 'image') {
                        existingComment.innerText += '[image]';
                    }
                });
            });
    });

    // 결과를 trim 하여 반환
    return comments.map(comment => ({
        commentId: comment.commentId,
        innerText: comment.innerText.trim(),
    }));
}

export function removeCommentMarkFromDOM(dom) {
    const comments = dom.querySelectorAll(`span[${COMMENT_ATTR_NAME}]`);
    comments.forEach(comment => {
        // 마크를 제거하기 위해 span 태그의 내용을 부모 노드에 직접 삽입
        while (comment.firstChild) {
            comment.parentNode.insertBefore(comment.firstChild, comment);
        }
        // 이제 빈 span 태그를 제거
        comment.parentNode.removeChild(comment);
    });
}

export function createCommentMarkInstance() {
    const CommentMarkInstance = Mark.create<CommentOptions, CommentStorage>({
        name: 'comment',

        addOptions() {
            return {
                HTMLAttributes: {},
                onCommentActivated: () => {},
            };
        },

        addAttributes() {
            return {
                commentIds: {
                    default: null,
                    parseHTML: el => (el as HTMLSpanElement).getAttribute(COMMENT_ATTR_NAME)?.trim().split(',') || null,
                    renderHTML: attrs => ({ [COMMENT_ATTR_NAME]: attrs.commentIds?.join(',') }),
                },
            };
        },

        parseHTML() {
            return [
                {
                    tag: `span[${COMMENT_ATTR_NAME}]`,
                    getAttrs: el => !!(el as HTMLSpanElement).getAttribute(COMMENT_ATTR_NAME)?.trim().split(',') && null,
                },
            ];
        },

        renderHTML({ HTMLAttributes }) {
            return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
        },

        onSelectionUpdate() {
            const { $from } = this.editor.state.selection;
            const marks = $from.marks();

            if (!marks.length) {
                this.storage.activeCommentIds = null;
                this.options.onCommentActivated(this.storage.activeCommentIds);
                return;
            }

            const commentMark = this.editor.schema.marks.comment;
            const activeCommentMark = marks.find(mark => mark.type === commentMark);
            this.storage.activeCommentIds = activeCommentMark?.attrs.commentIds || null;
            this.options.onCommentActivated(this.storage.activeCommentIds);
        },

        addStorage() {
            return {
                activeCommentIds: null,
                commentClass: {
                    normal: COMMENT_DEFAULT_CLASS,
                    hover: COMMENT_DEFAULT_HOVER_CLASS,
                    line: COMMENT_DEFAULT_LINE_CLASS,
                },
                customClasses: {},
            };
        },

        addCommands() {
            return {
                setComment:
                    commentId =>
                    ({ tr, state, dispatch }) => {
                        const { comment } = state.schema.marks;
                        const { from, to, empty } = state.selection;

                        if (empty) {
                            // 현재 선택된 영역이 없으면 빈 텍스트 노드를 생성
                            const emptyTextNode = state.schema.text(' ', [comment.create({ commentIds: [commentId] })]);
                            tr.replaceWith(from, to, emptyTextNode);
                        } else {
                            tr.doc.nodesBetween(from, to, (node, pos) => {
                                if (!node.isInline) return;

                                const nodeFrom = Math.max(pos, from);
                                const nodeTo = Math.min(pos + node.nodeSize, to);

                                const overlappingMarks = node.marks.filter(mark => mark.type === comment);

                                if (overlappingMarks.length) {
                                    overlappingMarks.forEach(mark => {
                                        const newCommentIds = Array.from(new Set([...mark.attrs.commentIds, commentId]));
                                        const markFrom = Math.max(nodeFrom, pos);
                                        const markTo = Math.min(nodeTo, pos + node.nodeSize);

                                        tr.addMark(markFrom, markTo, comment.create({ commentIds: newCommentIds }));
                                    });
                                } else {
                                    tr.addMark(nodeFrom, nodeTo, comment.create({ commentIds: [commentId] }));
                                }
                            });
                        }

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                unsetComment:
                    commentId =>
                    ({ tr, state, dispatch }) => {
                        const { comment } = state.schema.marks;

                        state.doc.descendants((node, pos) => {
                            if (!node.isInline) return;

                            node.marks.forEach(mark => {
                                if (mark.type === comment) {
                                    const newCommentIds = mark.attrs.commentIds.filter(id => id !== commentId);
                                    const start = pos;
                                    const end = pos + node.nodeSize;

                                    if (newCommentIds.length) {
                                        tr.addMark(start, end, comment.create({ commentIds: newCommentIds }));
                                    } else {
                                        tr.removeMark(start, end, mark);
                                    }
                                }
                            });
                        });

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                unsetCommentWithRange:
                    commentId =>
                    ({ tr, state, dispatch }) => {
                        const { comment } = state.schema.marks;
                        const { from, to } = state.selection;

                        tr.doc.nodesBetween(from, to, (node, pos) => {
                            const start = Math.max(from, pos);
                            const end = Math.min(to, pos + node.nodeSize);

                            node.marks.forEach(mark => {
                                if (mark.type === comment) {
                                    const newCommentIds = mark.attrs.commentIds.filter(id => id !== commentId);

                                    if (newCommentIds.length) {
                                        tr.addMark(start, end, comment.create({ commentIds: newCommentIds }));
                                    } else {
                                        tr.removeMark(start, end, mark);
                                    }
                                }
                            });
                        });
                        tr.setSelection(TextSelection.create(tr.doc, to, to));

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                removeAllCommentsWithId:
                    commentId =>
                    ({ tr, state, dispatch }) => {
                        const { comment } = state.schema.marks;

                        state.doc.descendants((node, pos) => {
                            if (!node.isInline) return;

                            node.marks.forEach(mark => {
                                if (mark.type === comment) {
                                    // 해당 commentId를 제외한 새로운 ID 배열 생성
                                    const newCommentIds = mark.attrs.commentIds.filter(id => id !== commentId);

                                    // 만약 새로운 ID 배열이 비어 있다면, 마크를 완전히 제거
                                    if (newCommentIds.length === 0) {
                                        const start = pos;
                                        const end = pos + node.nodeSize;
                                        tr.removeMark(start, end, mark);
                                    }
                                    // 그렇지 않다면 새로운 ID 배열로 마크 업데이트
                                    else if (newCommentIds.length !== mark.attrs.commentIds.length) {
                                        const start = pos;
                                        const end = pos + node.nodeSize;
                                        tr.removeMark(start, end, mark);
                                        tr.addMark(start, end, comment.create({ commentIds: newCommentIds }));
                                    }
                                }
                            });
                        });

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                removeAllComments:
                    () =>
                    ({ tr, state, dispatch }) => {
                        const { doc, schema } = state;
                        const { comment } = schema.marks;

                        // 문서의 모든 노드를 순회
                        doc.descendants((node, pos) => {
                            if (!node.isInline) return;

                            // 각 노드에서 'comment' 마크를 찾아 제거
                            node.marks.forEach(mark => {
                                if (mark.type === comment) {
                                    const start = pos;
                                    const end = pos + node.nodeSize;
                                    tr.removeMark(start, end, mark);
                                }
                            });
                        });

                        // 변경 사항 적용
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // Comment 영역에 class를 실제로 추가합니다.
                addClassToComment:
                    (commentId, className) =>
                    ({ tr, state, dispatch, view }) => {
                        const commentSpans = view.dom.querySelectorAll(`span[${COMMENT_ATTR_NAME}*="${commentId}"]`);
                        commentSpans.forEach(span => span.classList.add(className));
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // Comment 영역에서 class를 실제로 제거합니다.
                removeClassFromComment:
                    (commentId, className) =>
                    ({ tr, state, dispatch, view }) => {
                        const commentSpans = view.dom.querySelectorAll(`span[${COMMENT_ATTR_NAME}*="${commentId}"]`);
                        commentSpans.forEach(span => span.classList.remove(className));
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // 메모카드를 클릭했을때 에디터의 포커스가 해당 메모 요소의 노드로 이동합니다.
                moveFocusToComment:
                    (commentId, scrollInto = false, scrollIntoOption = { behavior: 'smooth', block: 'center' }) =>
                    ({ tr, state, view, dispatch }) => {
                        const { comment } = state.schema.marks;
                        let nodePositionwithSingleCommentId: number | null = null;
                        let nodePositionwithMultipleCommentIds: number | null = null;

                        // 해당 commentId를 가진 마크를 찾아서 노드로 포커스 이동, 입력받은 commentId 하나만 가진 마크를 찾으면 종료
                        state.doc.descendants((node, pos) => {
                            if (!node.isInline || nodePositionwithSingleCommentId) return;

                            node.marks.forEach(mark => {
                                // 노드의 마크를 순회하며 해당하는 commentId를 가진 마크중 단일 commentId를 가진 마크를 찾아 영역을 선택
                                if (mark.type === comment && isEqual(mark.attrs.commentIds, [commentId])) {
                                    if (nodePositionwithSingleCommentId === null) nodePositionwithSingleCommentId = pos;
                                }
                            });

                            // nodePositionwithSingleCommentId이 null일 떄, 단일 commentId를 가진 영역을 찾지 못한 것이므로 겹치는 영역 중에서 찾도록 시도
                            if (nodePositionwithSingleCommentId === null) {
                                node.marks.forEach(mark => {
                                    // 노드의 마크를 순회하며 해당하는 commentId를 가진 마크를 찾아 영역을 선택
                                    if (mark.type === comment && mark.attrs.commentIds.includes(commentId)) {
                                        if (nodePositionwithMultipleCommentIds === null) nodePositionwithMultipleCommentIds = pos;
                                    }
                                });
                            }
                        });

                        const nodePosition = nodePositionwithSingleCommentId || nodePositionwithMultipleCommentIds; // 단일 commentId를 가진 영역을 우선시해 nodePosition 선정

                        if (nodePosition !== null) {
                            const selection = TextSelection.create(tr.doc, nodePosition + 1, nodePosition + 1); // mark의 시작점에서는 isActive나 getAttributes 에 의해 선택되지 않아서 바로 뒤의 커서로 이동
                            tr.setSelection(selection);

                            if (scrollInto) {
                                const referenceNodeView = view.domAtPos(nodePosition)?.node;
                                if (referenceNodeView) {
                                    setTimeout(() => {
                                        (referenceNodeView as Element).scrollIntoView(scrollIntoOption);
                                    });
                                }
                            }
                        }

                        if (dispatch) dispatch(tr);
                        return true;
                    },

                // Comment에 적용되는 class를 변경합니다.
                setCommentClasses:
                    commentClasses =>
                    ({ tr, state, dispatch }) => {
                        this.storage.commentClass = commentClasses;

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // Comment의 class를 변경합니다.
                setCommentNormalClass:
                    className =>
                    ({ tr, state, dispatch }) => {
                        this.storage.commentClass.normal = className;

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // Hover시에 class를 변경합니다.
                setCommentHoverClass:
                    className =>
                    ({ tr, state, dispatch }) => {
                        this.storage.commentClass.hover = className;

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // Comment의 수직선 class를 변경합니다.
                setCommentLineClass:
                    className =>
                    ({ tr, state, dispatch }) => {
                        this.storage.commentClass.line = className;

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // 카드에 마우스 오버시 Decoration을 추가합니다.
                applyFocusedDecorationByComment:
                    commentId =>
                    ({ tr, state, dispatch }) => {
                        const { comment } = state.schema.marks;
                        const decorations: Decoration[] = [];
                        let start = Infinity;
                        let end = -Infinity;

                        state.doc.descendants((node, pos) => {
                            if (node.isInline) {
                                node.marks.forEach(mark => {
                                    if (mark.type === comment && mark.attrs.commentIds.includes(commentId)) {
                                        const from = pos;
                                        const to = pos + node.nodeSize;
                                        start = Math.min(start, from);
                                        end = Math.max(end, to);
                                        // 내부 hover 색상 추가
                                        decorations.push(Decoration.inline(from, to, { class: `active-comment ${this.storage.customClasses[commentId]?.hover || this.storage.commentClass.hover}` }));
                                    }
                                });
                            }
                        });

                        // 시작 부분에 수직선을 추가
                        decorations.push(Decoration.inline(start, start + 1, { class: `border-l-2 ${this.storage.customClasses[commentId]?.line || this.storage.commentClass.line}` }));
                        // 끝 부분에 수직선을 추가
                        decorations.push(Decoration.inline(end - 1, end, { class: `border-r-2 ${this.storage.customClasses[commentId]?.line || this.storage.commentClass.line}` }));

                        if (decorations.length > 0) {
                            const decorationSet = DecorationSet.create(state.doc, decorations);
                            tr.setMeta('focusedDecorations', decorationSet);
                        }

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // 마우스 오버로 인해 추가된 Decoration을 제거합니다.
                removeFocusedDecoration:
                    () =>
                    ({ tr, state, dispatch }) => {
                        const decorationSet = DecorationSet.empty;
                        tr.setMeta('focusedDecorations', decorationSet);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                /**
                 * 특정 Comment에 custom Decoration 클래스를 추가합니다.
                 * @param commentId Comment ID
                 * @param commentClasses Decoration을 적용할 클래스
                 * @returns ReturnType
                 * @example
                 * const diffCommentStyle = {
                 *     normal: 'bg-red-100',
                 *     hover: 'bg-red-200',
                 *     line: 'border-red-500',
                 * };
                 * editor.commands.applyDecorationClassToComment('e1c3bf7d-cf02-42e2-be0e-619f1238d435', diffCommentStyle);
                 */
                applyDecorationClassToComment:
                    (commentId, commentClasses) =>
                    ({ tr, state, dispatch }) => {
                        this.storage.customClasses[commentId] = commentClasses;

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                // 특정 Comment에 적용된 custom Decoration 클래스를 제거합니다.
                removeDecorationClassFromComment:
                    commentId =>
                    ({ tr, state, dispatch }) => {
                        delete this.storage.customClasses[commentId];

                        if (dispatch) dispatch(tr);
                        return true;
                    },
            };
        },
        addProseMirrorPlugins() {
            // comment 영역에 대한 기본 Decoration을 생성합니다.
            const commentDecorationPlugin = new Plugin({
                props: {
                    decorations: state => {
                        const decorations: Decoration[] = [];
                        const { doc } = state;
                        const commentMarkType = this.type.schema.marks.comment;
                        const customCommentClassKeys = Object.keys(this.storage.customClasses);

                        doc.descendants((node, pos) => {
                            if (node.isInline) {
                                node.marks.forEach(mark => {
                                    if (mark.type === commentMarkType) {
                                        const from = pos;
                                        const to = pos + node.nodeSize;
                                        const classKeys = intersection(mark.attrs.commentIds, customCommentClassKeys);
                                        if (classKeys.length > 0) {
                                            const decoration = Decoration.inline(from, to, { class: this.storage.customClasses[classKeys[0]].normal });
                                            decorations.push(decoration);
                                        } else {
                                            const decoration = Decoration.inline(from, to, { class: this.storage.commentClass.normal });
                                            decorations.push(decoration);
                                        }
                                    }
                                });
                            }
                        });

                        return DecorationSet.create(doc, decorations);
                    },
                },
            });

            // Comment 영역에 마우스 오버시 추가되는 Decoration을 생성합니다.
            const pluginKey = new PluginKey('commentHover');
            const hoverPlugin = new Plugin({
                key: pluginKey,
                state: {
                    init: (_, { tr }) => DecorationSet.empty,
                    apply: (tr, oldValue, oldState, newState) => {
                        // 마우스 오버로 인해 새로운 Decoration이 추가되었는지 확인합니다.
                        const hoveredCommentIds: string[] = tr.getMeta(pluginKey);
                        const customCommentClassKeys = Object.keys(this.storage.customClasses);
                        if (hoveredCommentIds) {
                            // 마우스 오버된 Comment ID에 대한 Decoration을 추가합니다.
                            const decorations: Decoration[] = [];
                            newState.doc.descendants((node, pos) => {
                                if (node.isInline) {
                                    const marks = node.marks.filter(mark => mark.type === this.type.schema.marks.comment && intersection(mark.attrs.commentIds, hoveredCommentIds).length > 0);
                                    marks.forEach(mark => {
                                        const classKeys = intersection(mark.attrs.commentIds, customCommentClassKeys);
                                        const from = pos;
                                        const to = pos + node.nodeSize;
                                        const decoration = Decoration.inline(from, to, {
                                            class: `active-comment ${this.storage.customClasses[classKeys[0]]?.hover || this.storage.commentClass.hover}`,
                                        });
                                        decorations.push(decoration);
                                    });
                                }
                            });
                            return DecorationSet.create(newState.doc, decorations);
                        }
                        return oldValue;
                    },
                },
                props: {
                    handleDOMEvents: {
                        copy: (view, event) => {
                            const { selection, doc } = view.state;
                            const content = selection.content().content;

                            // 선택된 컨텐츠를 DOM으로 변환
                            const dom = DOMSerializer.fromSchema(view.state.schema).serializeFragment(content);

                            // DOM에서 comment 마크 제거
                            removeCommentMarkFromDOM(dom);

                            // DOM Fragment를 임시 DOM 요소에 삽입
                            const tempDiv = document.createElement('div');
                            tempDiv.appendChild(dom);

                            // HTML 형식으로 복사
                            const copyHtml = tempDiv.innerHTML;
                            event.clipboardData?.setData('text/html', copyHtml);

                            // HTML을 텍스트로 변환하면서 줄바꿈 처리
                            const htmlToText = html => {
                                const tempDiv = document.createElement('div');
                                tempDiv.innerHTML = html;
                                return tempDiv.textContent || tempDiv.innerText;
                            };

                            const copyText = htmlToText(copyHtml.replace(/<br\s*\/?>/gi, '\n').replace(/<\/p>/gi, '\n')); // <p> 는 처리할필요 없음
                            event.clipboardData?.setData('text/plain', copyText);

                            event.preventDefault(); // 기본 복사 동작 방지
                            return true;
                        },
                    },
                    decorations(state) {
                        return this.getState(state);
                    },
                },
                view(view) {
                    let prevCommentIds: string[] = [];
                    const handleMouseOver = event => {
                        const { target } = event;
                        if (target.nodeType === Node.ELEMENT_NODE) {
                            const span = target.closest(`span[${COMMENT_ATTR_NAME}]`);
                            let newCommentIds = [];

                            if (span) {
                                newCommentIds = span.getAttribute(COMMENT_ATTR_NAME).split(',');
                            }

                            // 현재 메타 데이터와 새로운 메타 데이터가 다를 경우에만 트랜잭션 디스패치
                            if (!isEqual(prevCommentIds, newCommentIds)) {
                                prevCommentIds = newCommentIds;
                                view.dispatch(view.state.tr.setMeta(pluginKey, newCommentIds));
                            }
                        }
                    };
                    view.dom.addEventListener('mouseover', handleMouseOver);
                    return {
                        destroy() {
                            view.dom.removeEventListener('mouseover', handleMouseOver);
                        },
                    };
                },
            });

            // 선택된 메모영역의 시작과 끝에 수직선 스타일을 적용하는 플러그인
            const verticalLineDecorationPlugin = new Plugin({
                props: {
                    decorations: state => {
                        const decorations: Decoration[] = [];
                        const { from, to } = state.selection;
                        const commentRanges = new Map();
                        const customCommentClassKeys = Object.keys(this.storage.customClasses);

                        // 문서를 순회하며 각 commentId에 대한 시작과 끝 위치를 찾음
                        state.doc.descendants((node, pos) => {
                            if (node.isInline) {
                                node.marks.forEach(mark => {
                                    if (mark.type === this.type && mark.attrs.commentIds) {
                                        mark.attrs.commentIds.forEach(commentId => {
                                            if (!commentRanges.has(commentId)) {
                                                commentRanges.set(commentId, { start: pos, end: pos + node.nodeSize });
                                            } else {
                                                const range = commentRanges.get(commentId);
                                                range.start = Math.min(range.start, pos);
                                                range.end = Math.max(range.end, pos + node.nodeSize);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // 커서 위치에 있는 모든 commentId에 대한 시작과 끝 위치에 수직선 스타일 적용
                        commentRanges.forEach(({ start, end }, commentId) => {
                            const customClass = this.storage.customClasses[commentId]?.line || this.storage.commentClass.line;
                            if (start <= from && end >= to) {
                                decorations.push(Decoration.inline(start, start + 1, { class: `border-l-2 ${customClass}` }));
                                decorations.push(Decoration.inline(end - 1, end, { class: `border-r-2 ${customClass}` }));
                            }
                        });

                        return DecorationSet.create(state.doc, decorations);
                    },
                },
            });

            const focusedDecorationPluginKey = new PluginKey('focusedDecorationPlugin');
            const focusedDecorationPlugin = new Plugin({
                key: focusedDecorationPluginKey,

                // 플러그인의 초기 상태를 정의합니다. 여기서는 초기 장식 상태를 비어 있는 DecorationSet으로 설정합니다.
                state: {
                    init: () => DecorationSet.empty,
                    apply: (tr, oldState) => {
                        // 'addDecorations' 메타데이터를 검사합니다.
                        const decorations = tr.getMeta('focusedDecorations');

                        // 해당 메타데이터가 있으면 새로운 장식 상태를 반환합니다.
                        if (decorations) {
                            return decorations;
                        }

                        // 기본적으로 이전 상태를 반환합니다.
                        return oldState;
                    },
                },

                // 플러그인의 props를 정의합니다. 여기서는 decorations prop을 사용하여 현재 상태의 장식을 반환합니다.
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            });

            return [commentDecorationPlugin, hoverPlugin, verticalLineDecorationPlugin, focusedDecorationPlugin];
        },
    });
    return CommentMarkInstance;
}
