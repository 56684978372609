import { getMarkAttributes, Mark, mergeAttributes } from '@tiptap/core';

export interface TextStyleOptions {
    HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        textStyle: {
            /**
             * Remove spans without inline style attributes.
             */
            removeEmptyTextStyle: () => ReturnType;
        };
        fontSize: {
            /**
             * Set the font size
             */
            setFontSize: (size: string) => ReturnType;
            /**
             * Unset the font size
             */
            unsetFontSize: () => ReturnType;
        };
        backgroundColor: {
            /**
             * Set the background color
             */
            setBackgroundColor: (color: string) => ReturnType;
            /**
             * Unset the background color
             */
            unsetBackgroundColor: () => ReturnType;
        };
    }
}

const TextStyle = Mark.create<TextStyleOptions>({
    name: 'textStyle',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span',
                getAttrs: element => {
                    const hasStyles = (element as HTMLElement).hasAttribute('style');

                    if (!hasStyles) {
                        return false;
                    }

                    return {};
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addAttributes() {
        return {
            fontSize: {
                default: null,
                parseHTML: element => element.style.fontSize || null,
                renderHTML: attributes => {
                    if (!attributes.fontSize) {
                        return {};
                    }
                    return {
                        style: `font-size: ${attributes.fontSize}`,
                    };
                },
            },
            backgroundColor: {
                default: null,
                parseHTML: element => element.style.backgroundColor || null,
                renderHTML: attributes => {
                    if (!attributes.backgroundColor) {
                        return {};
                    }
                    return {
                        style: `background-color: ${attributes.backgroundColor}`,
                    };
                },
            },
            lineSpacing: {
                default: null,
                parseHTML: element => element.dataset.lineSpacing || null,
                renderHTML: attributes => {
                    if (!attributes.lineSpacing) {
                        return {};
                    }
                    return { style: `line-height: max(100%, ${attributes.lineSpacing})`, 'data-line-spacing': attributes.lineSpacing };
                },
            },
        };
    },

    addCommands() {
        return {
            removeEmptyTextStyle:
                () =>
                ({ state, commands }) => {
                    const attributes = getMarkAttributes(state, this.type);
                    const hasStyles = Object.entries(attributes).some(([, value]) => !!value);

                    if (hasStyles) {
                        return true;
                    }

                    return commands.unsetMark(this.name);
                },
            setFontSize:
                fontSize =>
                ({ commands }) => {
                    return commands.setMark(this.name, { fontSize: fontSize });
                },
            unsetFontSize:
                () =>
                ({ chain }) => {
                    return chain().setMark(this.name, { fontSize: null }).removeEmptyTextStyle().run();
                },
            setBackgroundColor:
                color =>
                ({ commands }) => {
                    return commands.setMark(this.name, { backgroundColor: color });
                },
            unsetBackgroundColor:
                () =>
                ({ chain }) => {
                    return chain().setMark(this.name, { backgroundColor: null }).removeEmptyTextStyle().run();
                },
        };
    },
});

export default TextStyle;
