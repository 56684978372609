import { mergeAttributes, Node } from '@tiptap/core';

export interface BulletListOptions {
    itemTypeName: string;
    HTMLAttributes: Record<string, any>;
    keepMarks: boolean;
    keepAttributes: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        bulletList: {
            /**
             * Toggle a bullet list
             */
            toggleBulletList: () => ReturnType;
        };
    }
}

export const WordBulletList = Node.create<BulletListOptions>({
    name: 'bulletList',
    addOptions() {
        return {
            itemTypeName: 'listItem',
            HTMLAttributes: {
                class: 'px-4 list-disc',
            },
            keepMarks: false,
            keepAttributes: true,
        };
    },
    priority: 900,
    group: 'block list',
    content() {
        return `${this.options.itemTypeName}+`;
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            listStyleType: {
                default: 'disc',
                parseHTML: element => {
                    return element.style.listStyleType || 'disc';
                },
                renderHTML: attributes => {
                    return {
                        ...attributes,
                        style: `list-style-type: ${attributes.listStyleType}`,
                    };
                },
            },
        };
    },
    parseHTML() {
        return [{ tag: 'ul' }];
    },
    renderHTML({ HTMLAttributes }) {
        return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addCommands() {
        return {
            toggleBulletList:
                () =>
                ({ commands, chain }) => {
                    if (this.options.keepAttributes) {
                        return chain().toggleList(this.name, this.options.itemTypeName, this.options.keepMarks).updateAttributes('listItem', this.editor.getAttributes('textStyle')).run();
                    }
                    return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks);
                },
        };
    },
});
