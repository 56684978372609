import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/core';

export type ColorOptions = {
    types: string[];
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        color: {
            /**
             * Set the text color
             */
            setColor: (color: string) => ReturnType;
            /**
             * Unset the text color
             */
            unsetColor: () => ReturnType;
        };
    }
}

const Color = Extension.create<ColorOptions>({
    name: 'color',

    addOptions() {
        return {
            types: ['textStyle'],
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    color: {
                        default: null,
                        parseHTML: element => element.style.color?.replace(/['"]+/g, '') || null,
                        renderHTML: attributes => {
                            if (!attributes.color) {
                                return {};
                            }

                            return {
                                style: `color: ${attributes.color}`,
                            };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setColor:
                color =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { color }).run();
                },
            unsetColor:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { color: null }).removeEmptyTextStyle().run();
                },
        };
    },
});

export default Color;
