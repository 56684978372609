<script lang="ts">
    /* eslint svelte/valid-compile: "off" */
    import type { NodeViewProps } from '@tiptap/core';
    import { NodeViewWrapper } from 'svelte-tiptap';
    export let node: NodeViewProps['node'];
    export let updateAttributes: NodeViewProps['updateAttributes'];
    export let editor: NodeViewProps['editor'];
    export let decorations: NodeViewProps['decorations'];
    export let selected: NodeViewProps['selected'];
    export let extension: NodeViewProps['extension'];
    export let getPos: NodeViewProps['getPos'];
    export let deleteNode: NodeViewProps['deleteNode'];
</script>

<NodeViewWrapper data-drag-handle>
    <div class="page-break mb-4" class:ring={selected && editor.isEditable}>
        <span class="before:content-['page break'] inline-block w-full border-b border-dashed border-gray-300 text-center text-xs font-normal text-gray-600">Page Break</span>
    </div>
</NodeViewWrapper>
