/**
 * 아래는 계산식 참고를 위한 BE에서 사용중인 aspose에 내장된 kotlin 코드로 작성된 ConvertUtil 클래스의 내용입니다.
 * object ConvertUtil {
 *      fun pointToPixel(points: Double): Double {
 *          val var4 = 96.0
 *          return points / 72.0 * var4
 *      }
 *      fun pointToPixel(points: Double, resolution: Double): Double {
 *          return points / 72.0 * resolution
 *      }
 *      fun pixelToPoint(pixels: Double): Double {
 *          val var4 = 96.0
 *          return pixels / var4 * 72.0
 *      }
 *      fun pixelToPoint(pixels: Double, resolution: Double): Double {
 *          return pixels / resolution * 72.0
 *      }
 *      fun inchToPoint(inches: Double): Double {
 *          return inches * 72.0
 *      }
 *      fun pointToInch(points: Double): Double {
 *          return points / 72.0
 *      }
 *      fun millimeterToPoint(millimeters: Double): Double {
 *          return millimeters * 2.834645669291339
 *      }
 * }
 */

export function convertToPt(valueWithUnit: string): number {
    const unit = valueWithUnit.replace(/[0-9]|\.|-/g, '');
    const value = parseFloat(valueWithUnit);

    if (Number.isNaN(value)) return 0;

    switch (unit) {
        case 'px':
            return value * 0.75; // px를 pt로 변환(96px = 72pt)
        case 'pt':
            return value; // pt는 변환 없음
        case 'cm':
            return value * 28.35; // cm를 pt로 변환(word에서 mm > pt 에서 2.835로 절삭하여 계산하는것으로 추정됨)
        case 'in':
            return value * 72; // in를 pt로 변환
        default:
            throw new Error(`Unsupported unit: ${unit}`);
    }
}
