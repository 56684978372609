import { convertToPt } from './utils';
import { Node, mergeAttributes } from '@tiptap/core';

type WrapType = 'inline' | 'square' | 'tight' | 'through' | 'top-bottom' | 'none';
type HorizontalAlignment = 'none' | 'left' | 'center' | 'right' | 'inside' | 'outside';
type VerticalAlignment = 'none' | 'top' | 'center' | 'bottom' | 'inside' | 'outside';
type RelativeHorizontalPosition = 'margin' | 'page' | 'column' | 'character' | 'left-margin' | 'right-margin' | 'inside-margin' | 'outside-margin';
type RelativeVerticalPosition = 'margin' | 'page' | 'paragraph' | 'line' | 'top-margin' | 'bottom-margin' | 'inside-margin' | 'outside-margin';

// 회전된 이미지의 bounding box 크기 계산
function calculateRotatedImageSize(width: number, height: number, angleDegrees: number): { rotatedWidth: number; rotatedHeight: number } {
    const angleRadians = (angleDegrees * Math.PI) / 180;
    const rotatedWidth = Math.abs(width * Math.cos(angleRadians)) + Math.abs(height * Math.sin(angleRadians));
    const rotatedHeight = Math.abs(width * Math.sin(angleRadians)) + Math.abs(height * Math.cos(angleRadians));
    return { rotatedWidth, rotatedHeight };
}

export const WordImage = Node.create({
    name: 'image',
    group: 'inline',
    selectable: true,
    allowGapCursor: false,
    inline: true,
    atom: true,
    draggable: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            source: {
                default: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
                parseHTML: element => element.style.backgroundImage.replace(/^url\(["']?/, '').replace(/["']?\)$/, ''),
                renderHTML: attributes => {
                    return {
                        class: 'bg-contain bg-center bg-no-repeat',
                        style: `background-image: url(${attributes.source});`,
                    };
                },
            },
            alternativeText: {
                default: '',
                parseHTML: element => element.dataset.alternativeText || '',
                renderHTML: attributes => {
                    return {
                        'data-alternative-text': attributes.alternativeText,
                        title: attributes.alternativeText || 'image',
                        'aria-label': attributes.alternativeText || 'image',
                    };
                },
            },
            width: {
                default: '1px',
                parseHTML: element => element.dataset.width || '1px',
                renderHTML: attributes => {
                    return {
                        'data-width': attributes.width,
                        style: attributes.wrapType === 'top-bottom' ? 'width: 100%' : `width: ${attributes.width}pt`,
                    };
                },
            },
            height: {
                default: '1px',
                parseHTML: element => element.dataset.height || '1px',
                renderHTML: attributes => {
                    return {
                        'data-height': attributes.height,
                        style: `height: ${attributes.height}pt`,
                    };
                },
            },
            wrapType: {
                default: 'inline' as WrapType,
                parseHTML: element => element.dataset.wrapType || 'inline',
                renderHTML: attributes => {
                    return {
                        'data-wrap-type': attributes.wrapType,
                        style: [
                            ['top-bottom'].includes(attributes.wrapType) ? 'display: block' : 'display: inline-block',
                            ['square', 'tight', 'through'].includes(attributes.wrapType) ? 'float: left' : '',
                            ['none'].includes(attributes.wrapType) ? 'position: absolute' : '',
                        ].join(';'),
                    };
                },
            },
            behindText: {
                default: false,
                parseHTML: element => JSON.parse(element.dataset.behindText || 'false'),
                renderHTML: attributes => {
                    return {
                        'data-behind-text': attributes.behindText,
                        style: ['none'].includes(attributes.wrapType) && attributes.behindText ? `z-index: -1` : 'z-index: 1',
                    };
                },
            },
            rotation: {
                default: 0,
                parseHTML: element => Number(element.dataset.rotation),
                renderHTML: attributes => {
                    return {
                        'data-rotation': attributes.rotation,
                        style: `transform: rotate(${attributes.rotation}deg)`,
                    };
                },
            },
            horizontalAlignment: {
                default: 'none' as HorizontalAlignment,
                parseHTML: element => element.dataset.horizontalAlignment || 'none',
                renderHTML: attributes => {
                    return {
                        'data-horizontal-alignment': attributes.horizontalAlignment,
                    };
                },
            },
            verticalAlignment: {
                default: 'none' as VerticalAlignment,
                parseHTML: element => element.dataset.verticalAlignment || 'none',
                renderHTML: attributes => {
                    return {
                        'data-vertical-alignment': attributes.verticalAlignment,
                    };
                },
            },
            relativeHorizontalPosition: {
                default: 'page' as RelativeHorizontalPosition,
                parseHTML: element => element.dataset.relativeHorizontalPosition || 'page',
                renderHTML: attributes => {
                    return {
                        'data-relative-horizontal-position': attributes.relativeHorizontalPosition,
                    };
                },
            },
            relativeVerticalPosition: {
                default: 'page' as RelativeVerticalPosition,
                parseHTML: element => element.dataset.relativeVerticalPosition || 'page',
                renderHTML: attributes => {
                    return {
                        'data-relative-vertical-position': attributes.relativeVerticalPosition,
                    };
                },
            },
            left: {
                default: '0pt',
                parseHTML: element => element.dataset.left || '0pt',
                renderHTML: attributes => {
                    return {
                        'data-left': attributes.left,
                    };
                },
            },
            right: {
                default: '0pt',
                parseHTML: element => element.dataset.right || '0pt',
                renderHTML: attributes => {
                    return {
                        'data-right': attributes.right,
                    };
                },
            },
            top: {
                default: '0pt',
                parseHTML: element => element.dataset.top || '0pt',
                renderHTML: attributes => {
                    return {
                        'data-top': attributes.top,
                    };
                },
            },
            bottom: {
                default: '0pt',
                parseHTML: element => element.dataset.bottom || '0pt',
                renderHTML: attributes => {
                    return {
                        'data-bottom': attributes.bottom,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.word-image[role=img]',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ role: 'img', class: 'word-image' }, HTMLAttributes)];
    },
    addNodeView() {
        return ({ editor, HTMLAttributes, decorations, extension, getPos, node }) => {
            const { rotatedWidth, rotatedHeight } = calculateRotatedImageSize(convertToPt(node.attrs.width), convertToPt(node.attrs.height), node.attrs.rotation);

            const dom = document.createElement('div');
            Object.keys(HTMLAttributes).forEach(key => {
                if (!['class', 'style'].includes(key)) dom.setAttribute(key, HTMLAttributes[key]);
            });
            dom.classList.add('word-image');
            dom.setAttribute('role', 'img');
            dom.contentEditable = 'false';
            if (['top-bottom'].includes(node.attrs.wrapType)) {
                dom.style.display = 'block';
            } else {
                dom.style.display = 'inline-block';
            }
            if (['square', 'tight', 'through'].includes(node.attrs.wrapType)) {
                dom.style.float = 'left';
            }
            if (['none'].includes(node.attrs.wrapType)) {
                dom.style.position = 'absolute';
                if (node.attrs.behindText) {
                    dom.style.zIndex = `-1`;
                } else {
                    dom.style.zIndex = '1';
                }
            }

            const wrapperElement = document.createElement('div');
            wrapperElement.classList.add('inline-block', 'relative');
            wrapperElement.style.width = `${rotatedWidth}pt`;
            wrapperElement.style.height = `${rotatedHeight}pt`;

            const imgElement = document.createElement('div');
            imgElement.classList.add('absolute', 'bg-contain', 'bg-no-repeat', 'top-1/2', 'left-1/2');
            imgElement.style.width = node.attrs.width;
            imgElement.style.height = node.attrs.height;
            imgElement.style.backgroundImage = `url(${node.attrs.source})`;
            if (['top-bottom'].includes(node.attrs.wrapType)) {
                const diffWidth = rotatedWidth - convertToPt(node.attrs.width);
                if (diffWidth > 0) imgElement.style.transform = `translateX(calc(-50% - ${diffWidth}pt)) translateY(-50%) rotate(${node.attrs.rotation}deg)`;
                else imgElement.style.transform = `translateX(calc(-50% + ${-diffWidth}pt)) translateY(-50%) rotate(${node.attrs.rotation}deg)`;
            } else {
                imgElement.style.transform = `translate(-50%, -50%) rotate(${node.attrs.rotation}deg)`;
            }

            wrapperElement.append(imgElement);
            dom.append(wrapperElement);

            return {
                dom,
            };
        };
    },
});
