import { Extension } from '@tiptap/core';
import { BhsnBlockReferenceExtension } from './BhsnBlockReferenceExtension';
import { BhsnReferenceExtension } from './BhsnReferenceExtension';
import { BhsnBlockReferenceUnlinkExtension } from './BhsnBlockReferenceUnlinkExtension';
import { BhsnReferenceUnlinkExtension } from './BhsnReferenceUnlinkExtension';

export interface BhsnExtensionPackOptions {
    [key: string]: any;
}

export const BhsnNormalExtensionPack = Extension.create<BhsnExtensionPackOptions>({
    name: 'bhsnExtensionPack',

    addExtensions() {
        const extensions = [BhsnBlockReferenceUnlinkExtension, BhsnReferenceUnlinkExtension];
        return extensions;
    },
});

export const BhsnStandardFormExtensionPack = Extension.create<BhsnExtensionPackOptions>({
    name: 'bhsnExtensionPack',

    addExtensions() {
        const extensions = [BhsnBlockReferenceExtension, BhsnReferenceExtension];
        return extensions;
    },
});
