<script lang="ts">
    import type { NodeViewProps } from '@tiptap/core';
    import { NodeViewContent, NodeViewWrapper } from 'svelte-tiptap';
    import { twMerge } from 'tailwind-merge';

    export let node: NodeViewProps['node'];
    export let selected: NodeViewProps['selected'] = false;
</script>

<NodeViewWrapper class={twMerge('svelte-component inline', selected && 'selected')}>
    <span class="bhsn-reference">
        <!-- justify 와 같은 정렬상태에 따라 inline 영역이 공백을 채우면서 늘어날 수 있다. 이 떄문에 앞뒤로 span을 붙여줌 -->
        <span />
        <span
            class="rounded p-0.5 font-bold"
            class:bg-yellow-100={!node.attrs['focused-input']}
            class:ring-yellow-100={!node.attrs['focused-input']}
            class:bg-orange-300={node.attrs['focused-input']}
            class:ring-orange-300={node.attrs['focused-input']}
            class:ring={selected}
            data-drag-handle
            focused-input={node.attrs['focused-input']}
            contenteditable="false"
        >
            {#if !node.content.size}
                {node.attrs['data-name']}
            {/if}
            <NodeViewContent as="span" />
        </span>
        <span />
    </span>
</NodeViewWrapper>
