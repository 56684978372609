import { Extension } from '@tiptap/core';
import { convertToPt } from './utils';

export interface LineSpacingOptions {
    types: string[];
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        lineSpacing: {
            setLineSpacing: (height: string) => ReturnType;
            unsetLineSpacing: () => ReturnType;
            setSpaceBefore: (height: string) => ReturnType;
            unsetSpaceBefore: () => ReturnType;
            setSpaceAfter: (height: string) => ReturnType;
            unsetSpaceAfter: () => ReturnType;
        };
    }
}

export const LineSpacing = Extension.create<LineSpacingOptions>({
    name: 'lineSpacing',

    addOptions() {
        return {
            types: ['heading', 'paragraph'],
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineSpacing: {
                        parseHTML: element => element.dataset.lineSpacing,
                        renderHTML: attributes => {
                            if (attributes.lineSpacing && attributes.lineSpacingRule === 'exactly') {
                                return { style: `line-height: ${attributes.lineSpacing}`, 'data-line-spacing': attributes.lineSpacing };
                            }
                            return { 'data-line-spacing': attributes.lineSpacing };
                        },
                    },
                    lineSpacingRule: {
                        // at_least, exactly, multiple
                        default: 'multiple',
                        parseHTML: element => element.dataset.lineSpacingRule,
                        renderHTML: attributes => {
                            return { 'data-line-spacing-rule': attributes.lineSpacingRule };
                        },
                    },
                    spaceBefore: {
                        default: '0pt',
                        parseHTML: element => (element.style.marginTop ? `${convertToPt(element.style.marginTop)}pt` : '0pt'),
                        renderHTML: attributes => {
                            if (!attributes.spaceBefore) {
                                return {};
                            }

                            return { style: `margin-top: ${attributes.spaceBefore};` };
                        },
                    },
                    spaceAfter: {
                        default: '0pt',
                        parseHTML: element => (element.style.marginBottom ? `${convertToPt(element.style.marginBottom)}pt` : '0pt'),
                        renderHTML: attributes => {
                            if (!attributes.spaceAfter) {
                                return {};
                            }

                            return { style: `margin-bottom: ${attributes.spaceAfter};` };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setLineSpacing:
                (height: string) =>
                ({ commands }) => {
                    return this.options.types.every(type => commands.updateAttributes(type, { lineSpacing: height }));
                },
            unsetLineSpacing:
                () =>
                ({ commands, editor }) => {
                    // 현재 선택된 블록의 폰트 사이즈를 가져온다.
                    const fontSize = editor.getAttributes('textStyle')?.fontSize;
                    if (!fontSize) {
                        return this.options.types.every(type => commands.resetAttributes(type, 'lineSpacing'));
                    } else {
                        return this.options.types.every(type => commands.updateAttributes(type, { lineSpacing: fontSize && !Number.isNaN(Number(fontSize)) ? `${fontSize}pt` : fontSize }));
                    }
                },
            setSpaceBefore:
                (height: string) =>
                ({ commands }) => {
                    return this.options.types.every(type => commands.updateAttributes(type, { spaceBefore: height }));
                },
            unsetSpaceBefore:
                () =>
                ({ commands }) => {
                    return this.options.types.every(type => commands.resetAttributes(type, 'spaceBefore'));
                },
            setSpaceAfter:
                (height: string) =>
                ({ commands }) => {
                    return this.options.types.every(type => commands.updateAttributes(type, { spaceAfter: height }));
                },
            unsetSpaceAfter:
                () =>
                ({ commands }) => {
                    return this.options.types.every(type => commands.resetAttributes(type, 'spaceAfter'));
                },
        };
    },
});
