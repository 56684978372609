import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core';

export interface OrderedListOptions {
    itemTypeName: string;
    HTMLAttributes: Record<string, any>;
    keepMarks: boolean;
    keepAttributes: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        orderedList: {
            /**
             * Toggle an ordered list
             */
            toggleOrderedList: () => ReturnType;
        };
    }
}

export const WordOrderedList = Node.create<OrderedListOptions>({
    name: 'orderedList',
    addOptions() {
        return {
            itemTypeName: 'listItem',
            HTMLAttributes: {
                class: 'px-4 list-decimal',
            },
            keepMarks: false,
            keepAttributes: true,
        };
    },
    priority: 900,
    group: 'block list',
    content() {
        return `${this.options.itemTypeName}+`;
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            start: {
                default: 1,
                parseHTML: element => {
                    return element.hasAttribute('start') ? parseInt(element.getAttribute('start') || '', 10) : 1;
                },
            },
            listStyleType: {
                default: 'decimal',
                parseHTML: element => {
                    return element.style.listStyleType || 'decimal';
                },
                renderHTML: attributes => {
                    return {
                        ...attributes,
                        style: `list-style-type: ${attributes.listStyleType}`,
                    };
                },
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'ol',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        const { start, ...attributesWithoutStart } = HTMLAttributes;

        return start === 1 ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0] : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addCommands() {
        return {
            toggleOrderedList:
                () =>
                ({ commands, chain }) => {
                    if (this.options.keepAttributes) {
                        return chain().toggleList(this.name, this.options.itemTypeName, this.options.keepMarks).updateAttributes('listItem', this.editor.getAttributes('textStyle')).run();
                    }
                    return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks);
                },
        };
    },
    addKeyboardShortcuts() {
        return {
            'Mod-Shift-7': () => this.editor.commands.toggleOrderedList(),
        };
    },
});
