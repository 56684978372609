import { Node, mergeAttributes } from '@tiptap/core';

export const WordHeader = Node.create({
    name: 'header',
    group: 'header',
    content: 'block+',
    parseHTML() {
        return [
            {
                tag: 'div.word-header',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ class: 'word-header' }, HTMLAttributes), 0];
    },
});
